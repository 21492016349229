<template>
  <div class="question">
    <div>
      <div class="row mt20">
        <div class="col-xs-12">
          <span class="bold fs-medium-small">Upload an image of your inspiration</span>
          <div class="center-xs">
            <upload-image v-model="mood_picture" :has-next="false" full-width />
          </div>
        </div>
        <div class="col-xs-12 image-upload">
          <text-area label="Describe your vision" v-model="description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextArea from './Fields/Textarea.vue'
import BaseQuestion from '../../../mixins/questionnaire/baseQuestion'
import UploadImage from './Fields/UploadImage.vue'

export default {
  mixins: [BaseQuestion],
  components: {
    TextArea,
    UploadImage
  },
  data () {
    return {
    }
  },
  computed: {
    mood_picture: {
      get () {
        return this.answer ? this.answer.mood_picture : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), mood_picture: val }
      }
    },
    description: {
      get () {
        return this.answer ? this.answer.description : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), description: val }
      }
    }
  },
  methods: {
    setQuestionAnswer () {
      this.setAnswer({
        question: this.question.name.replace(/_.+$/, ''),
        answer: this.choice
      })
    },
    getQuestionAnswer () {
      if (this.question && this.question.name) {
        const answer = this.questionAnswer(this.question.name.replace(/_.+$/, ''))
        if (answer) {
          Object.keys(answer).forEach(key => {
            if (typeof this[key] !== 'undefined') {
              this[key] = answer[key]
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$mine-shaft: color(mine-shaft);
$white: color(white);

.question {
  width: 100%;
  @include respond-to(tablet) {
    width: 50%;
    margin: 0 auto;
  }
  img {
    max-width: 100%;
  }
  &__button-next {
    display: block;
    padding: 20px 15%;
    border: 2px solid $mine-shaft;
    font-size: 16px;
    background-color: $white;
    cursor: pointer;
    outline: none;
    margin: 10px auto;
  }
}
</style>
