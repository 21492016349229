<template>
  <button @click="!disabled && $emit('click')" :disabled="disabled">
    <slot>Continue</slot>
  </button>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" type="text/scss" scoped>
  button {
    display: block;
    padding: 15px 7.5rem;
    margin: 20px auto;
    border: none;
    text-transform: capitalize;
    letter-spacing: 0.02rem;
    font-size: 16px;
    background-color: color(accent, $colors-theme, hover);
    border-radius: 3px;
    color: color(primary, $colors-background);
    &[disabled] {
      background-color: color(accent, $colors-theme, default)
    }
  }
</style>
