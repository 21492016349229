<template>
  <div class="w-100">
    <header class="py35 pl20">
      <div class="container">
        <div class="row middle-sm center-xs cl-primary">
          <h1 class="col-sm-9 mb10">
            Thank you for describing your vision!
          </h1>
          <h4>We will send you your first 3 designs 24-72 hours from now. Please stay tuned.</h4>
        </div>
      </div>
    </header>
    <div class="container pb60">
      <div class="row m0 pt15 questionnaire-end">
        <div class="col-xs-12 col-md-8 col-md-offset-2">
          <p class="fs-medium">
            Your design vision has been sent to our designers. Await an email from them, or write one yourself - in case if you missed something. Here is our email address: <a class="links" href="mailto:help@couturme.com">help@couturme.com</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      agreed: true,
      authToken: '',
      dropInInstance: null,
      paymentIsValid: false,
      patternData: {},
      errors: []
    }
  },
  components: {
  },
  computed: {
    ...mapGetters('questionnaire', ['question', 'results', 'isComplete']),
    ...mapGetters('user', ['isLoggedIn']),
    canSubmit () {
      return this.paymentIsValid && this.agreed
    }
  },
  methods: {
    ...mapActions('questionnaire', ['obtainToken'])
  }
}
</script>
<style lang="scss" scoped>
  .questionnaire-pay {
    border-radius: 3px;
    color: color(primary, $colors-background);
    background-color: color(accent, $colors-theme, hover);
    padding: 10px 30px;
    border: none;
    &[disabled] {
      background-color: color(tertiary, $colors-theme);
      cursor: not-allowed;
    }
    &__wrapper {
      width: 100%;
      text-align: center;
    }
  }
</style>
