<template>
  <div class="question">
    <div>
      <div class="fs-medium">
        Change mode here:
      </div>
      <button-select v-model="currentType" :options="['Conceal', 'Flaunt']" />
      <div class="fs-medium">
        Check items you want to <span class="weight-400">{{ currentType.toLowerCase() }}</span>:
      </div>
      <radio-buttons v-model="currentSource" :options="options" multiple />
    </div>
    <div class="fs-medium-small">
      Understanding your preferences will help us design a dress that you'll love.
    </div>
    <continue-button @click="onToNext">
      Finish
    </continue-button>
  </div>
</template>

<script>
import BaseQuestion from '../../../mixins/questionnaire/baseQuestion'
import ButtonSelect from './Fields/ButtonSelect.vue'
import RadioButtons from './Fields/RadioButtons.vue'
import ContinueButton from './Fields/ContinueButton'

export default {
  mixins: [BaseQuestion],
  components: {
    ContinueButton,
    ButtonSelect,
    RadioButtons
  },
  data () {
    return {
      currentType: 'Conceal',
      availableOptions: [
        'Legs',
        'Cleavage',
        'Midsection',
        'Back',
        'Rear',
        'Arms',
        'Shoulders'
      ]
    }
  },
  computed: {
    currentAnswer () {
      return {
        concealed: (this.answer.concealed || []),
        flaunted: (this.answer.flaunted || [])
      }
    },
    currentSource: {
      get () {
        return this.currentType === 'Conceal' ? this.currentAnswer.concealed : this.currentAnswer.flaunted
      },
      set (value) {
        if (this.currentType === 'Conceal') {
          this.answer = {
            ...this.answer,
            concealed: value
          }
        } else {
          this.answer = {
            ...this.answer,
            flaunted: value
          }
        }
      }
    },
    options () {
      if (this.currentType === 'Conceal') {
        return this.availableOptions.filter(o => !this.currentAnswer.flaunted.includes(o))
      } else {
        return this.availableOptions.filter(o => !this.currentAnswer.concealed.includes(o))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$mine-shaft: color(mine-shaft);
$white: color(white);

.question {
  width: 100%;
  @include respond-to(tablet) {
    width: 50%;
    margin: 0 auto;
  }
  &__button-next {
    display: block;
    padding: 20px 15%;
    border: 2px solid $mine-shaft;
    font-size: 16px;
    background-color: $white;
    cursor: pointer;
    outline: none;
    margin: 10px auto;
  }
}
</style>
