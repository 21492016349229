<template>
  <div class="question">
    <div v-if="offersLoading" class="container">
      <div class="row middle-sm center-xs cl-primary">
        <div class="col-sm-9">
          <h4>Please wait while we retrieve available items...</h4>

          <img src="../../../assets/loading.svg" alt="">
        </div>
      </div>
    </div>
    <div v-if="'Belt' === currentType.type && addBelts !== 'Yes'">
      <div class="row middle-sm center-xs cl-primary">
        <div class="col-sm-9">
          <h4>Would you like to add a crystal sash?</h4>
          <radio-buttons
            :has-next="false"
            v-model="addBelts"
            label=""
            :options="['Yes', 'No']"
          />
        </div>
      </div>
    </div>
    <div v-if="'Embroidery' === currentType.type && addEmbroidery !== 'Yes'">
      <div class="row middle-sm center-xs cl-primary">
        <div class="col-sm-9">
          <h4>Would you like to add floral appliqu�s to your gown?</h4>
          <radio-buttons
            :has-next="false"
            v-model="addEmbroidery"
            label=""
            :options="['Yes', 'No']"
          />
        </div>
      </div>
    </div>
    <div v-if="currentType.type && ('Belt' !== currentType.type || addBelts === 'Yes') && ('Embroidery' !== currentType.type || addEmbroidery === 'Yes')">
      <cloth-part-selector :options="currentType.items"
                           :type="currentType.type"
                           :value="items[currentType.type]"
                           @input="handleItemsChange"
                           v-if="currentType"
      />
    </div>
    <continue-button @click="goToNext" />
  </div>
</template>

<script>
import BaseQuestion from '../../../mixins/questionnaire/baseQuestion'
import ClothPartSelector from './Fields/ClothPartSelector.vue'
import ContinueButton from './Fields/ContinueButton'
import { mapGetters } from 'vuex'
import RadioButtons from './Fields/RadioButtons'

export default {
  mixins: [BaseQuestion],
  components: {
    RadioButtons,
    ContinueButton,
    ClothPartSelector
  },
  data () {
    return {
      addBelts: '',
      addEmbroidery: '',
      fulfilledTypes: [],
      layerOrder: [
        'Top',
        'Skirt',
        'Pants',
        'Sleeve',
        'Belt',
        'Embroidery'
      ]
    }
  },
  mounted () {
    this.fulfilledTypes = []
  },
  computed: {
    ...mapGetters('questionnaire', ['offers', 'offersLoading', 'answerByQuestionName']),
    availableTypes () {
      if (this.offers && this.offers.fittingItems) {
        return Object.keys(this.offers.fittingItems)
          .sort((a, b) => {
            return this.layerOrder.indexOf(a) - this.layerOrder.indexOf(b)
          })
          .filter(type => this.offers.fittingItems[type] && this.offers.fittingItems[type].length)
          .filter(type => type !== 'Sleeve' || this.answerByQuestionName('question_sleeve_type') !== 'Sleeveless')
          .map(type => ({
            type,
            items: this.getTypeItems(type)
          }))
      }
      return []
    },
    currentType () {
      const fulfilledTypes = this.fulfilledTypes.map(t => t.type)
      return this.availableTypes.filter(t => !fulfilledTypes.includes(t.type))[0] || {}
    },
    items: {
      get () {
        return this.answer ? this.answer.items || {} : {}
      },
      set (val) {
        this.answer = {
          ...(this.answer || {}),
          items: {
            ...val
          }
        }
      }
    }
  },
  watch: {
    addBelts: 'handleRadioButtonsChange',
    addEmbroidery: 'handleRadioButtonsChange'
  },
  methods: {
    handleItemsChange (type, items) {
      this.$store.commit('questionnaire/setAnswer', {
        ...this.answer,
        items: {
          ...(this.answer.items || {}),
          [type]: items
        }
      })
    },
    handleRadioButtonsChange (value) {
      if (value === 'No') {
        this.handleItemsChange(this.currentType.type, [])
        this.goToNext()
      }
    },
    getItemType (item, type) {
      if (item['Element Type']) {
        const typeTag = item['Element Type'].find(t => t.includes(type))
        if (typeTag && typeTag.includes(':')) {
          const [, value] = typeTag.split(/:\s?/)
          if (value) return value.trim()
        }
      }
      return null
    },
    groupElements (elements, groupTag) {
      const colorField = 'Please select your favorite colors:'
      if (elements && elements.length < 5) return elements
      return elements
        .filter(item => (item.Icons && item.Icons.length) || (item.Image && item.Image.length))
        .reduce((items, item) => {
          if (item['Element Type']) {
            const groupBy = this.getItemType(item, groupTag)
            if (groupBy) {
              const mainItem = items.find(i => i.groupBy === groupBy)
              if (!mainItem) {
                items.push({
                  ...item,
                  groupBy,
                  similar: [item]
                })
              } else {
                mainItem.similar.push(item)
                if (item[colorField].includes('White') && !mainItem[colorField].includes('White')) {
                  const mainItemIndex = items.findIndex(i => i.groupBy === groupBy)
                  items[mainItemIndex] = {
                    ...item,
                    groupBy,
                    similar: mainItem.similar
                  }
                }
              }
            } else {
              items.push(item)
            }
          }
          return items
        }, [])
    },
    getTypeItems (type) {
      const availableItems = this.applyElementFilters(this.offers.fittingItems[type])
      if (type === 'Sleeve') {
        return this.groupElements(availableItems, 'Sleeve Type')
      }
      if (type === 'Top') {
        return this.groupElements(availableItems, 'Neckline Type')
      }
      if (type === 'Skirt') {
        return this.groupElements(availableItems, 'Skirt Type')
      }
      return availableItems
        .filter(item => (item.Icons && item.Icons.length) || (item.Image && item.Image.length))
    },
    applyElementFilters (elements) {
      return elements
      /* let filtered = elements.slice(0)
      const sleeveAnswer = this.answerByQuestionName('question_sleeve_type')
      if (sleeveAnswer && sleeveAnswer.value) {
        const sleeveAddOnFilter = sleeveAnswer.value === 'Sleeveless' ? 'Sleeve: no' : ''
        if (sleeveAddOnFilter) {
          filtered = filtered.filter(e => !e['Sleeve Add On'] || e['Sleeve Add On'] === sleeveAddOnFilter)
        }

        const sleeveLengthFilter = 'Sleeve Length Category: ' + sleeveAnswer.value
        if (this.currentType.type === 'Sleeve' && sleeveLengthFilter) {
          filtered = filtered.filter(e => !e['Element Type'] && e['Element Type'].includes(sleeveLengthFilter))
        }
      }
      return filtered */
    },
    goToNext () {
      this.fulfilledTypes.push(this.currentType)
      if (typeof window !== 'undefined') {
        const question = document.body.querySelector('.scroll-trigger')
        const { x, y } = question.getBoundingClientRect()
        window.scrollTo(x, y)
      }
      if (this.fulfilledTypes.length === this.availableTypes.length) {
        this.onToNext()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$mine-shaft: color(mine-shaft);
$white: color(white);

.question {
  width: 100%;
  @include respond-to(tablet) {
    width: 80%;
    margin: 0 auto;
  }
  &__button-next {
    display: block;
    padding: 20px 15%;
    border: 2px solid $mine-shaft;
    font-size: 16px;
    background-color: $white;
    cursor: pointer;
    outline: none;
    margin: 10px auto;
  }
}
</style>
