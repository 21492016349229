<template>
  <div id="questionnaire">
    <question-wrapper />
  </div>
</template>

<script>
import QuestionWrapper from '../components/blocks/Questionnaire/QuestionWrapper.vue'

export default {
  components: { QuestionWrapper },
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     if (!vm.$store.getters['user/isLoggedIn']) {
  //       vm.$store.commit('ui/setAuthElem', 'register')
  //       vm.$bus.$emit('modal-show', 'modal-signup')
  //       vm.$router.push('/')
  //     }
  //   })
  // },
  data () {
    return {
      mobileFilters: false
    }
  },
  methods: {
    openFilters () {
      this.mobileFilters = true
    },
    closeFilters () {
      this.mobileFilters = false
    },
    notify () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: 'Please select the field which You like to sort by',
        action1: { label: 'OK' }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .category-title {
    line-height: 65px;
  }

  @media (max-width: 64em) {
    .products-list {
      max-width: 530px;
    }
  }

  @media (max-width: 770px) {
    .category-title {
      margin: 0;
      font-size: 36px;
      line-height: 40px;
    }
  }

  .close-container {
    left: 0;
  }

  .close {
    margin-left: auto;
  }
  .questionnaire-question {
    display: flex;
    justify-content: center;
    text-align: center;
  }
</style>
