<template>
  <router-link :to="`/questionnaire/offer/${variant.id}`" class="offer-variant">
    <div class="image" @click="$emit('click', $event)">
      <img :src="modelImageSrc" alt="" :class="{'-loading': paintColor && !clothImage}" ref="model-image-front">
      <svg width="200px" height="200px" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 100 100" v-if="paintColor && !clothImage"
           preserveAspectRatio="xMidYMid" class="lds-double-ring loading-image" style="background: none;"
      >
        <circle cx="50" cy="50" fill="none" stroke-linecap="round" r="40" stroke-width="4" :stroke="paintColorHex === '#FCFCFC' ? '#ccc' : paintColorHex"
                stroke-dasharray="62.83185307179586 62.83185307179586" transform="rotate(331.819 50 50)"
        >
          <animateTransform attributeName="transform" type="rotate" calcMode="linear"
                            values="0 50 50;360 50 50" keyTimes="0;1" dur="1.3s" begin="0s" repeatCount="indefinite"
          />
        </circle>
        <circle cx="50" cy="50" fill="none" stroke-linecap="round" r="35" stroke-width="4" :stroke="paintColorHex"
                stroke-dasharray="54.97787143782138 54.97787143782138" stroke-dashoffset="54.97787143782138"
                transform="rotate(-331.819 50 50)" style="opacity: 0.5"
        >
          <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;-360 50 50"
                            keyTimes="0;1" dur="1.3s" begin="0s" repeatCount="indefinite"
          />
        </circle>
      </svg>
      <img :src="image.url" alt="" v-for="image in variant.thumbs" v-if="!paintColor && !clothImage" :key="image.url">
      <img :src="clothImage" alt="" v-if="clothImage">
    </div>
    <div class="back-image" @click="$emit('click', $event)">
      <img :src="modelImageBackSrc" alt="" class="model" :class="{'-loading': paintColor && !clothBackImage}">
      <svg width="200px" height="200px" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 100 100" v-if="paintColor && !clothBackImage"
           preserveAspectRatio="xMidYMid" class="lds-double-ring loading-image" style="background: none;"
      >
        <circle cx="50" cy="50" fill="none" stroke-linecap="round" r="40" stroke-width="4" :stroke="paintColorHex === '#FCFCFC' ? '#ccc' : paintColorHex"
                stroke-dasharray="62.83185307179586 62.83185307179586" transform="rotate(331.819 50 50)"
        >
          <animateTransform attributeName="transform" type="rotate" calcMode="linear"
                            values="0 50 50;360 50 50" keyTimes="0;1" dur="1.3s" begin="0s" repeatCount="indefinite"
          />
        </circle>
        <circle cx="50" cy="50" fill="none" stroke-linecap="round" r="35" stroke-width="4" :stroke="paintColorHex"
                stroke-dasharray="54.97787143782138 54.97787143782138" stroke-dashoffset="54.97787143782138"
                transform="rotate(-331.819 50 50)" style="opacity: 0.5"
        >
          <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;-360 50 50"
                            keyTimes="0;1" dur="1.3s" begin="0s" repeatCount="indefinite"
          />
        </circle>
      </svg>
      <img :src="image.url" alt="" v-for="image in variant.backImages" v-if="!paintColor && !clothBackImage" :key="image.url">
      <img :src="clothBackImage" alt="" v-if="clothBackImage">
    </div>
    <span class="offer-variant__name fs-medium-small">{{ variant.name }}</span>
    <span class="weight-700 center-xs">{{ variant.price ? `${variant.price}` : 0 | price }}</span>
    <!--<div class="offer-variant__actions center-xs weight-700">

    </div>-->
  </router-link>
</template>
<script>
import { combineCloth, hexToRgb } from '../../../../services/color'
import { defaultModelPicture } from '../../../../utils'

export default {
  name: 'Offer',
  props: {
    model: {
      type: Object,
      default: () => ({
        'Main Image': []
      })
    },
    modelBack: {
      type: Object,
      default: () => ({
        'Main Image': []
      })
    },
    variant: {
      type: Object,
      default: () => ({
        images: [],
        id: '',
        name: ''
      })
    }
  },
  data () {
    return {
      clothImage: '',
      clothBackImage: ''
    }
  },
  mounted () {
    const color = this.paintColor
    if (color) {
      combineCloth(this.variant.thumbs, 512, 512 * this.modelImageRatio, color).then(canvas => {
        this.clothImage = canvas.toDataURL('image/png')
      })
      combineCloth(this.variant.backImages, 512, 512 * this.modelImageRatio, color).then(canvas => {
        this.clothBackImage = canvas.toDataURL('image/png')
      })
    }
  },
  computed: {
    paintColor () {
      return this.variant.paintColor && this.variant.paintColor.color ? hexToRgb(this.variant.paintColor.color) : null
    },
    paintColorHex () {
      return this.variant.paintColor && this.variant.paintColor.color ? this.variant.paintColor.color : null
    },
    modelImageSrc () {
      if (this.variant.model && this.variant.model.front && this.variant.model.front.mainImage) {
        return this.variant.model.front.mainImage
      }
      if (this.model && this.model['Main Image'] && this.model['Main Image'][0]) {
        return this.model['Main Image'][0].url
      }
      return defaultModelPicture('front', this.$store.getters['questionnaire/answerByQuestionName']('question_model'))
    },

    modelImageRatio () {
      const model = this.model
      const modelImage = model && model['Main Image'] && model['Main Image'][0]

      if (modelImage && modelImage.thumbnails && modelImage.thumbnails.large) {
        let modelThumb = modelImage.thumbnails.large

        if (modelThumb.height && modelThumb.width) {
          return modelThumb.height / modelThumb.width
        }
      }
      return 1.4114
    },

    modelImageBackSrc () {
      if (this.variant.model && this.variant.model.back && this.variant.model.back.mainImage) {
        return this.variant.model.back.mainImage
      }
      const model = this.modelBack
      if (model && model['Main Image'] && model['Main Image'][0]) {
        return model['Main Image'][0].url
      }
      return defaultModelPicture('back', this.$store.getters['questionnaire/answerByQuestionName']('question_model'))
    },
    colorString () {
      if (this.variant && this.variant.origin) {
        return Object.keys(this.variant.origin).reduce((result, key) => {
          if (this.variant.origin[key] &&
            this.variant.origin[key]['Please select your favorite colors:'] &&
            this.variant.origin[key]['Element Name'] !== 'Sleeveless'
          ) {
            result.push(key + ' - ' + this.variant.origin[key]['Please select your favorite colors:'].join(', '))
          }
          return result
        }, []).join(', ')
      }
      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
  .offer-variant {
    display: inline-block;
    width: 210px;
    text-align: center;
    margin: 10px;
    cursor: pointer;
    position: relative;
    &__actions {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      .cart-icon {
        margin: 0;
      }
    }
    &__name {
      display: inline-flex;
      min-height: 70px;
      align-content: center;
      align-items: center;
    }
    &:hover {
      text-decoration: none;
      border-bottom: 0;
      .image {
        display: none;
      }
      .back-image {
        display: block;
      }
      &:after {
        content: none;
      }
    }
    .back-image {
      display: none;
    }
    .image, .back-image {
      position: relative;
      width: 210px;
      height: 300px;
      img {
        &.-loading {
          opacity: 0;
        }
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 100%;
      }

      .loading-image {
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
        left: 0;
      }
    }
  }
</style>
