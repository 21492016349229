<template>
  <div class="units" :class="[align]">
    <div class="units__toggle-button" :class="{'active': value === 'inch'}" @click="setUnits('inch')">
      inch
    </div>
    <div class="units__toggle-button" :class="{'active': value === 'cm'}" @click="setUnits('cm')">
      cm
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: 'inch',
      validator: units => {
        return ['inch', 'cm'].includes(units)
      }
    },
    align: {
      type: String,
      default: 'left'
    }
  },
  methods: {
    setUnits (value) {
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }
}
</script>
<style lang="scss" type="text/scss" scoped>
  .units {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    &.right {
      justify-content: flex-end;
    }
    &.left {
      justify-content: flex-start;
    }
    &__toggle-button {
      font-size: 12px;
      padding: 0 5px;
      color: color(tertiary);
      font-weight: bold;
      text-transform: uppercase;
      text-align: left;
      cursor: pointer;
      &:first-of-type {
        padding-left: 0;
      }
      &.active {
        color: color(primary);
      }
    }
  }
</style>
