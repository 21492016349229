<template>
  <div class="w-100">
    <header class="py35 pl20">
      <div class="container">
        <div class="links">
          <div class="semi-link" @click="$store.commit('questionnaire/setPrevious')" v-if="previousQuestion">
            Back
          </div>
          <div class="restart-link" :class="{'-full' : !previousQuestion}" @click="handleRestart">
            Restart
          </div>
        </div>
        <div class="row middle-sm center-xs">
          <h3 class="col-sm-9 mb10 scroll-trigger">
            {{ question && Object.keys(question).length ? question.title : 'Questionnaire complete' }}
          </h3>
        </div>
      </div>
    </header>
    <div class="container pb60">
      <div class="row m0 pt15 questionnaire-question" ref="content">
        <component :is="currentQuestionComponent" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import RegularQuestion from './RegularQuestion.vue'
import FigureQuestion from './FigureQuestion.vue'
import FitQuestion from './FitQuestion.vue'
import ConcealQuestion from './ConcealQuestion.vue'
import CustomMoodQuestion from './CustomMoodQuestion.vue'
import MoodQuestion from './MoodQuestion.vue'
import ColorQuestion from './ColorQuestion.vue'
import ModelQuestion from './ModelQuestion.vue'
import { trackEvent } from '../../../utils/analytics'

export default {
  components: {
    RegularQuestion,
    FigureQuestion,
    FitQuestion,
    ConcealQuestion,
    CustomMoodQuestion,
    MoodQuestion,
    ColorQuestion,
    ModelQuestion
  },
  computed: {
    ...mapGetters('questionnaire', ['question', 'previousQuestion']),
    currentQuestionComponent () {
      if (this.question && this.question.componentName) {
        return this.question.componentName
      }
      return 'RegularQuestion'
    }
  },
  watch: {
    question () {
      if (this.$refs && this.$refs.content) {
        this.$refs.content.scrollTop = 0
      }
    }
  },
  methods: {
    handleRestart () {
      trackEvent('user-action', 'questionnaire-restart')
      this.$store.commit('questionnaire/newQuestionnaire')
      this.$router.push('/questionnaire')
    }
  }
}
</script>
<style lang="scss" scoped>
  .links {
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    display: grid;
    grid-template-areas: 'a b';
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
  .semi-link, .restart-link  {
    cursor: pointer;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .semi-link {
    grid-area: a;
  }
  .restart-link {
    grid-area: b;
    text-align: right;
  }
  .pl20 {
    @include smaller-than(tablet) {
      padding-left: 0;
    }
  }
</style>
