<template>
  <div class="w-100">
    <header class="py35 pl20">
      <div class="container">
        <div class="link" @click="$router.push('/questionnaire/offers')">
          Back
        </div>
      </div>
    </header>
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <carousel :per-page="1" navigation-enabled
                    navigation-prev-label=""
                    navigation-next-label=""
                    class="product-carousel"
                    ref="carousel"
          >
            <slide>
              <div class="image" :class="{'-blurred': loading}">
                <img :src="modelImageSrc" alt="" class="model">
                <canvas width="1417px" height="2000px" class="cloth" ref="paintBoard" id="paintBoard" />
              </div>
            </slide>
            <slide>
              <div class="image" :class="{'-blurred': loading}">
                <img :src="modelImageBackSrc" alt="" class="model">
                <canvas width="1417px" height="2000px" class="cloth" ref="paintBoardBack" id="paintBoardBack" />
              </div>
            </slide>
          </carousel>
        </div>
        <div class="col-xs-12 col-md-6 pl30">
          <div class="row middle-sm cl-primary">
            <h3>{{ `${colorName} ${selectedOffer.name}` }}</h3>
          </div>
          <div class="fs-medium weight-700">
            {{ (selectedOffer.price || 0) | price }}
          </div>
          <div class="mt20">
            <div @click="handleAddToCart" class="button-add-to-cart">
              Add to shopping cart
            </div>
          </div>
          <div class="mt20">
            <a class="button-add-to-cart -inverse" href="//calendly.com/couturme" target="_blank">
              Free design consultation
            </a>
          </div>
          <div class="mt20"/>
          <div class="mt20">
            <div class="link" @click="handleHideModel">
              {{ modelVisible ? 'Hide model' : 'Show model' }}
            </div>
          </div>
          <div class="mt20">
            <div class="color-selector" v-if="canRecolor">
              <div>
                Change dress color:
              </div>
              <compact-picker v-model="currentColor" :palette="palette" />
            </div>
          </div>
          <div class="mt20">
            <div class="color-selector" v-if="backOptions && backOptions.length">
              <div>
                Change neckline back style:
              </div>
              <div>
                <cloth-render v-for="back in backOptions" :item="back" :min-size="75"
                              :width="100" :height="100" :show-name="false"
                              :class="{'-selected': back === topBackImage}"
                              @click="handleBackSelect(back)"
                              :key="back.name"
                />
              </div>
            </div>
          </div>
          <div class="product-collateral toggle-content accordion-open">
            <dl class="collateral-tabs">
              <accordion name="Details">
                <div>
                  <p class="pa1 product-description" v-for="sentence in productDescriptions" :key="sentence">
                    {{ sentence }}
                  </p>
                  <ul class="disc featurepoints">
                    <li class="pa1" v-if="productFabric.description"><b>Material:</b> {{ productFabric.description }}</li>
                    <li class="pa1" v-else><b>Material:</b> {{ hardCodedMaterial }}</li>
                    <li class="pa1"><b>Waist:</b> {{ waistTag.join(', ') }}</li>
                  </ul>
                </div>
              </accordion>
              <accordion name="Custom fit" ref="measurements">
                <div>
                  <div class="row no-side-margins">
                    <base-checkbox
                      class="col-xs-12"
                      id="sendMeasurementsLaterCheckbox"
                      @click="sendMeasurementsLater = !sendMeasurementsLater"
                      @blur="$v.sendMeasurementsLater.$touch()"
                      v-model="sendMeasurementsLater"
                      :validation="{
                        condition: !$v.sendMeasurementsLater.required && $v.sendMeasurementsLater.$error,
                        text: 'Field is required'
                      }"
                    >
                      I will send measurements later
                    </base-checkbox>
                    <div class="col-xs-12 mt10" v-if="!sendMeasurementsLater">
                      <units-switch align="right" v-model="units" />
                    </div>
                    <div class="col-xs-6" v-if="!sendMeasurementsLater">
                      <text-input display-units :units="units" label="Bust" v-model="bust" />
                      <text-input display-units :units="units" label="Under Bust" v-model="underBust" />
                      <text-input display-units :units="units" label="Waist" v-model="waist" />
                    </div>
                    <div class="col-xs-6" v-if="!sendMeasurementsLater">
                      <text-input display-units :units="units" label="Full Hips" v-model="fullHips" />
                      <text-input display-units :units="units" label="Upper Arm" v-model="upperArm" />
                      <text-input display-units :units="units" label="Height" v-model="height" />
                    </div>
                  </div>
                  <div class="fit-advisor">
                    <p class="pa1">
                      If you have any questions, please email us: <a href="mailto:help@couturme.com">help@couturme.com</a>
                    </p>
                  </div>
                </div>
              </accordion>
              <accordion name="Delivery">
                <custom-scroll-bar>
                  <div>
                    <p class="pa1">
                      Due to the extremely high demand, please allow 90 days to receive your gown.
                    </p>
                    <p class="pa1">
                      Your personalized dress will be ready to ship between
                      {{ deliveryDate.from }} - {{ deliveryDate.to }} if ordered today.
                    </p>
                    <p class="pa1">
                      All orders are shipped worldwide via FedEx. Please select a shipping method at the checkout.
                    </p>
                    <p class="pa1">
                      If you are not happy with the gown, we gladly accept returns within 30 days. Please read the return policy
                      <router-link to="/faq#returns">here</router-link>.
                    </p>
                  </div>
                </custom-scroll-bar>
              </accordion>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {required} from 'vuelidate/lib/validators'
import {mapActions, mapGetters} from 'vuex'
import CustomScrollBar from 'vue-custom-scrollbar'
import {Compact} from 'vue-color'
import moment from 'moment'
import TextInput from './Fields/Input'
import Accordion from './Fields/Accordion'
import ClothRender from './Fields/ClothPartRender'
import {colorizeImageData, combineCloth, hexToRgb, invertImageData, nameHexColor} from '../../../services/color'
import {loadCORSImage} from '../../../services/toDataURL'
import UnitsSwitch from '../Form/UnitsSwitch'
import {trackEvent} from '../../../utils/analytics'
import {defaultModelPicture} from '../../../utils'

export default {
  name: 'OfferDetailed',
  components: {
    UnitsSwitch,
    'compact-picker': Compact,
    CustomScrollBar,
    TextInput,
    Accordion,
    ClothRender
  },
  validations: {
    sendMeasurementsLater: {
      required
    }
  },
  data () {
    return {
      loading: false,
      units: 'inch',
      modelVisible: true,
      currentColor: '#ffffff',
      currentTab: 0,
      palette: [
        '#FFFFFF', // '#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF',
        '#feffef', // '#333333', '#808080', '#feffef', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF',
        '#f7f7ef' // '#000000', '#666666', '#f7f7ef', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E'
      ],
      bust: '',
      underBust: '',
      waist: '',
      fullHips: '',
      upperArm: '',
      height: '',
      topBackImage: null,
      layerOrder: [
        'Top',
        'Skirt',
        'Pants',
        'Sleeve',
        'Belt',
        'Embroidery'
      ],
      sendMeasurementsLater: false,
      deliveryDate: {
        from: moment().add(75, 'days').format('MMM D'),
        to: moment().add(90, 'days').format('MMM D')
      }
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['selectedOffer', 'offers', 'answerByQuestionName']),
    colorName () {
      if (this.currentColor.hex ? this.currentColor.hex !== '#ffffff' : this.currentColor !== '#ffffff') {
        return nameHexColor(this.currentColor.hex || this.currentColor)
      }
      return ''
    },
    modelImageSrc () {
      if (this.selectedOffer.model && this.selectedOffer.model.front && this.selectedOffer.model.front.mainImage) {
        return this.selectedOffer.model.front.mainImage
      }
      const model = this.offers.model
      if (model && model['Main Image'] && model['Main Image'][0]) {
        return model['Main Image'][0].url
      }
      return defaultModelPicture('front', this.$store.getters['questionnaire/answerByQuestionName']('question_model'))
    },
    modelImageBackSrc () {
      if (this.selectedOffer.model && this.selectedOffer.model.back && this.selectedOffer.model.back.mainImage) {
        return this.selectedOffer.model.back.mainImage
      }
      const model = this.offers.modelBack
      if (model && model['Main Image'] && model['Main Image'][0]) {
        return model['Main Image'][0].url
      }
      return defaultModelPicture('back', this.$store.getters['questionnaire/answerByQuestionName']('question_model'))
    },
    canRecolor () {
      return !this.selectedOffer.paintColor && (this.mainColor.includes('White') || this.mainColor.includes('Nude'))
    },
    hardCodedMaterial () {
      const priceAnswer = this.answerByQuestionName('question_price')
      const priceAnswerValue = priceAnswer && priceAnswer.value ? priceAnswer.value : priceAnswer
      if (['Bridal Budget $1499- $2499', 'Bridal Budget $2499- $5499', 'Bridal Budget $5499- $7499'].includes(priceAnswerValue)) {
        return '100% Silk satin. Fully lined in 100% Silk. Dry clean only. Ethically hand made in Europe.'
      }
      return 'Polyester/Spandex. Fully lined in 100% Polyester. Dry clean only. Ethically hand made in Europe.'
    },
    mainColor () {
      const FIELD_CODE = 'Please select your favorite colors:'
      return Object.values(this.selectedOffer.origin || {}).reduce((colors, element) => {
        if (element[FIELD_CODE]) {
          element[FIELD_CODE].forEach(color => {
            if (!colors.includes(color)) {
              colors.push(color)
            }
          })
        }
        return colors
      }, [])
    },
    waistTag () {
      const FIELD_CODE = 'Waist'
      return Object.values(this.selectedOffer.origin || {}).reduce((waists, element) => {
        if (element[FIELD_CODE]) {
          element[FIELD_CODE].forEach(waist => {
            if (!waists.includes(waist) && waist.toLowerCase() !== 'any') {
              waists.push(waist)
            }
          })
        }
        return waists
      }, [])
    },
    backOptions () {
      if (this.selectedOffer.origin && this.selectedOffer.origin.Top &&
        this.selectedOffer.origin.Top['Back image'].length && this.selectedOffer.origin.Top['Back image'].length > 1) {
        return this.selectedOffer.origin.Top['Back image'].filter(i => i.url).map(i => ({
          id: i.id,
          name: i.filename,
          url: i.url,
          Image: [i]
        }))
      }
      return []
    },
    backImages () {
      return this.selectedOffer.backImages.map(i => {
        return i.layer !== 'Top' || !this.topBackImage ? i : {
          ...i,
          name: this.topBackImage.name,
          url: this.topBackImage.url
        }
      })
    },
    offerForCart () {
      return {
        ...this.selectedOffer,
        backImage: this.topBackImage ? this.topBackImage.id : null,
        sendMeasurementsLater: this.sendMeasurementsLater,
        measurements: {
          bust: this.bust,
          underBust: this.underBust,
          waist: this.waist,
          fullHips: this.fullHips,
          upperArm: this.upperArm,
          height: this.height
        },
        ...(this.currentColor ? {
          paintColor: this.currentColor
        } : {})
      }
    },
    measurementsAreFilled () {
      if (!this.sendMeasurementsLater) {
        return +this.bust > 0 && +this.underBust > 0 && +this.waist > 0 && +this.fullHips > 0 && +this.upperArm > 0 && +this.height > 0
      }
      return true
    },
    offerLayers () {
      return Object.keys(this.selectedOffer.origin)
        .filter(l => this.layerOrder.includes(l))
        .sort((a, b) => {
          return this.layerOrder.indexOf(a) - this.layerOrder.indexOf(b)
        })
    },
    productDescriptions () {
      return this.offerLayers
        .map(layer => {
          const layerItem = this.selectedOffer.origin[layer]
          return layerItem.Description || `${layerItem['Element Name']}`
        })
    },
    productFitInfo () {
      return this.offerLayers
        .map(layer => {
          const layerItem = this.selectedOffer.origin[layer]
          if (layerItem.Element && layerItem.Element.length) {
            const type = layerItem.Element[0]
            return type.includes(':') ? {
              text: type.split(':')[1],
              layer
            } : null
          }
          return null
        })
        .filter(s => s)
    },
    productFabric () {
      return this.selectedOffer.paintColor && this.selectedOffer.paintColor.fabrics
        ? this.selectedOffer.paintColor.fabrics.find(f => f.description)
        : {}
    }
  },
  watch: {
    'currentColor.hex': 'drawImage'
  },
  mounted () {
    this.drawImage()
    trackEvent('user-action', 'product-view', this.selectedOffer.id)
  },
  methods: {
    ...mapActions('questionnaire', ['selectOffer']),
    handleAddToCart () {
      if (this.sendMeasurementsLater || this.measurementsAreFilled) {
        this.$store.dispatch('cart/add', { item: this.offerForCart, price: 1000 })
        trackEvent('user-action', 'product-add-to-cart', this.selectedOffer.id)
        this.$modal.show('dialog', {
          title: 'Thank you',
          text: 'An item has been added to cart. Want to review your order?',
          buttons: [{
            title: 'View cart',
            class: 'accent',
            handler: () => {
              this.$modal.hide('dialog')
              this.$router.push('/checkout')
            }
          }, {
            class: 'close', title: 'Close'
          }, {
            class: 'bordered', title: 'Continue shopping'
          }]
        })
      } else {
        trackEvent('user-action', 'product-measure-validation-fail', this.selectedOffer.id)
        this.$modal.show('dialog', {
          title: 'Measurements',
          text: 'Please, provide measurement information.',
          buttons: [{
            class: 'close', title: 'Close'
          }, {
            class: 'bordered', title: 'Continue shopping'
          }]
        })
        if (this.$refs && this.$refs.measurments && !this.$refs.measurments.open) {
          this.$refs.measurments.handleTabExpand()
        }
      }
    },
    handleBackSelect (back) {
      this.topBackImage = back
      this.drawImage().then(() => {
        if (this.$refs && this.$refs.carousel) {
          this.$refs.carousel.goToLastSlide()
        }
      })
    },
    drawImage () {
      if (this.$refs && this.$refs.paintBoard) {
        this.loading = true
        const boardContext = this.$refs.paintBoard.getContext('2d')
        const boardWidth = this.$refs.paintBoard.width
        const boardHeight = this.$refs.paintBoard.height
        boardContext.clearRect(0, 0, boardWidth, boardHeight)
        return this.getRenderedCloth(false).then(canvas => {
          boardContext.drawImage(canvas, 0, 0, boardWidth, boardHeight)
          boardContext.imageSmoothingEnabled = true
          boardContext.translate(0.5, 0.5)
          this.loading = false
          if (this.$refs.paintBoardBack) {
            this.getRenderedClothBack(false).then(canvas => {
              const backBoardContext = this.$refs.paintBoardBack.getContext('2d')
              const boardWidth = this.$refs.paintBoardBack.width
              const boardHeight = this.$refs.paintBoardBack.height
              backBoardContext.clearRect(0, 0, boardWidth, boardHeight)
              backBoardContext.drawImage(canvas, 0, 0, boardWidth, boardHeight)
              backBoardContext.imageSmoothingEnabled = true
              backBoardContext.translate(0.5, 0.5)
            })
          }
        }).catch(() => {
          this.loading = false
        })
      }
      return Promise.resolve()
    },
    invert (imageData) {
      return invertImageData(imageData)
    },
    colorize (imageData, color) {
      return colorizeImageData(imageData, color)
    },
    handleHideModel () {
      this.modelVisible = !this.modelVisible
      this.drawImage()
    },
    getRenderedCloth () {
      const color = this.selectedOffer.paintColor ? hexToRgb(this.selectedOffer.paintColor.color) : this.currentColor.rgba || this.currentColor
      return loadCORSImage(this.modelImageSrc).then(image => {
        const c = document.createElement('canvas')
        c.width = image.width
        c.height = image.height
        const ctx = c.getContext('2d')
        if (this.modelVisible) {
          ctx.drawImage(image, 0, 0, c.width, c.height)
        }
        return combineCloth(this.selectedOffer.images, c.width, c.height, color).then(clothCanvas => {
          ctx.drawImage(clothCanvas, 0, 0, c.width, c.height)
          return c
        })
      })
    },
    getRenderedClothBack () {
      const color = this.selectedOffer.paintColor ? hexToRgb(this.selectedOffer.paintColor.color) : this.currentColor.rgba || this.currentColor
      return loadCORSImage(this.modelImageBackSrc).then(image => {
        const c = document.createElement('canvas')
        c.width = image.width
        c.height = image.height
        const ctx = c.getContext('2d')
        if (this.modelVisible) {
          ctx.drawImage(image, 0, 0, c.width, c.height)
        }
        return combineCloth(this.backImages, c.width, c.height, color).then(clothCanvas => {
          ctx.drawImage(clothCanvas, 0, 0, c.width, c.height)
          return c
        })
      })
    },
    getCanvasToDownload () {
      return this.getRenderedCloth(false)
        .then(clothCanvas => {
          const c = document.createElement('canvas')
          const ctx = c.getContext('2d')
          return loadCORSImage(this.modelImageSrc).then(image => {
            c.width = image.width
            c.height = image.height
            if (this.modelVisible) {
              ctx.drawImage(image, 0, 0, c.width, c.height)
            }
            ctx.drawImage(clothCanvas, 0, 0, c.width, c.height)
            return c
          })
        })
    },
    handleDownload () {
      return this.getCanvasToDownload(this.modelVisible)
        .then(canvas => {
          this.download(canvas, this.selectedOffer.name.trim().replace(' ', '-'))
        })
    },
    download (canvas, filename) {
      /// create an "off-screen" anchor tag
      const lnk = document.createElement('a')
      let e

      /// the key here is to set the download attribute of the a tag
      lnk.download = filename

      /// convert canvas content to data-uri for link. When download
      /// attribute is set the content pointed to by link will be
      /// pushed as "download" in HTML5 capable browsers
      lnk.href = canvas.toDataURL('image/png;base64')

      /// create a "fake" click-event to trigger the download
      if (document.createEvent) {
        e = document.createEvent('MouseEvents')
        e.initMouseEvent('click', true, true, window,
          0, 0, 0, 0, 0, false, false, false,
          false, 0, null)

        lnk.dispatchEvent(e)
      } else if (lnk.fireEvent) {
        lnk.fireEvent('onclick')
      }
    }
  }
}
</script>
<style lang="scss">
  .vc-compact {
    box-shadow: none !important;
    .vc-compact-color-item {
      width: 50px;
      height: 50px;
      margin: 2px;
      border: 4px solid color(primary, $colors-background);
      &[aria-selected] {
        outline: 2px solid color(primary, $colors-border);
      }
      .vc-compact-dot {
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        display: none;
      }
    }
  }
  .product-carousel {
    .VueCarousel-navigation-next {
      top: 40%;
      right: 75px;
    }
    .VueCarousel-navigation-prev {
      top: 40%;
      left: 75px;
    }
  }
  .product-collateral {
    .ps__rail-y {
      display: block;
    }
  }
</style>
<style lang="scss" scoped>
  .button-add-to-cart {
    @extend %button-mixin;
    @extend %button-bordered-mixin;
    color: color(primary, $colors-background);
    width: auto;
    font-size: 14px;
    font-weight: 300;
    padding: 10px 20px;
    background-color: color(accent, $colors-theme, hover);

    &.-inverse {
      border: 1px solid color(accent, $colors-theme);
      color: color(accent, $colors-theme);
      background-color: color(primary, $colors-background);

      &:after {
        display: none;
      }
    }

    .md-icon {
      margin: 0;
      margin-right: 10px;
    }
    &.-gray {
      background-color: color(secondary);
    }
    .md-icon {
      $size: 18px;
      font-size: $size - 2;
      line-height: 1;
      width: $size;
      min-width: $size;
      height: $size;
      min-height: $size;
      font-weight: 200;
    }
  }
  .image {
    position: relative;
    width: 100%;
    &.-blurred {
      filter: blur(15px);
    }
    .model {
      max-width: 100%;
      opacity: 0;
      &.-opaque {
        opacity: 1;
      }
    }
    .cloth {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }
  .controls {
    display: flex;
    align-items: flex-start;
  }
  .color-selector {
    display: inline-block;
    margin: 0 10px;
  }
  .link {
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .product-collateral {
    margin-top: 14px;
    margin-bottom: 100px;
    border-bottom: 1px solid color(primary, $colors-border);
    .std {
      letter-spacing: .8px;
      padding-bottom: 12px;
    }
    .icon-arrow-right-grey {
      float: right;
      transition: transform .25s;
      width: 8px;
      height: 21px;
    }
    .tab-container {
      overflow: hidden;
    }
    .pv-open-returns {
      text-decoration: underline;
      color: color(primary, $colors-border);
      &:visited {
        color: color(primary, $colors-border);
        text-decoration: underline;
      }
      &:hover {
        color: color(primary, $colors-border);
        text-decoration: underline;
      }
      &:active {
        color: color(primary, $colors-border);
        text-decoration: underline;
      }
    }
    .pv-open-shipping {
      text-decoration: underline;
      color: color(primary, $colors-border);
      &:visited {
        color: color(primary, $colors-border);
        text-decoration: underline;
      }
      &:hover {
        color: color(primary, $colors-border);
        text-decoration: underline;
      }
      &:active {
        color: color(primary, $colors-border);
        text-decoration: underline;
      }
    }
    .pv-open-promo-video {
      text-decoration: underline;
      color: color(primary, $colors-border);
      &:visited {
        color: color(primary, $colors-border);
        text-decoration: underline;
      }
      &:hover {
        color: color(primary, $colors-border);
        text-decoration: underline;
      }
      &:active {
        color: color(primary, $colors-border);
        text-decoration: underline;
      }
    }
    .disc {
      list-style: disc;
      padding: 0 0 0 17px;
    }
  }
  .collateral-tabs {
    margin: 0;
  }
  .tab {
    text-transform: uppercase;
    padding: 12px 12px 12px 0;
    border-top: 1px solid color(primary, $colors-border);
  }
  .tab-container {
    padding-top: 0;
    margin-left: 0;
  }
  @media(max-width: 770px) {
    .russia-shipping-collateral {
      border-bottom: none;
    }
  }
  .service-table .toggle-content dd td {
    margin: 0;
  }
  .lang-ar .product-description {
    display: none;
  }
</style>
