<template>
  <div class="questionnaire-page">
    <image-selector :options="currentModels" width="32.5%" element-class="-image"
                    v-model="answer" :borders="false" @changed="onToNext"
    />
  </div>
</template>

<script>
import BaseQuestion from '../../../mixins/questionnaire/baseQuestion'
import ImageSelector from './Fields/ImageSelector'
import { mapGetters, mapActions } from 'vuex'

export default {
  mixins: [ BaseQuestion ],
  components: { ImageSelector },
  data () {
    return {}
  },
  mounted () {
    this.getModels()
  },
  computed: {
    ...mapGetters('questionnaire', ['models', 'answerByQuestionName']),
    choice () {
      return {}
    },
    firstQuestionAnswer () {
      const answer = this.answerByQuestionName('question_1')
      return answer.value || answer
    },
    currentModels () {
      return this.models
        .map(m => ({
          ...m,
          next: this.nextQuestion
        }))
    },
    nextQuestion () {
      // return this.firstQuestionAnswer === 'Design wedding gowns' ? 'question_3_wedding' : 'question_3'
      return 'question_color'
    }
  },
  methods: {
    ...mapActions('questionnaire', ['getModels']),
    setQuestionAnswer () {
      this.setAnswer({
        question: this.question.name.replace(/_.+$/, ''),
        answer: this.choice
      })
    },
    getQuestionAnswer () {
      if (this.question && this.question.name) {
        const answer = this.questionAnswer(this.question.name.replace(/_.+$/, ''))
        if (answer) {
          Object.keys(answer).forEach(key => {
            if (typeof this[key] !== 'undefined') {
              this[key] = answer[key]
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  $mine-shaft: color(mine-shaft);
  $white: color(white);
  .questionnaire-page {
    width: 100%;
  }
  .question {
    &__single-button {
      width: 100%;
      display: block;
      padding: 20px 15%;
      border: 2px solid $mine-shaft;
      font-size: 16px;
      background-color: $white;
      cursor: pointer;
      outline: none;
      margin-bottom: 10px;
    }
    &__single-image {
      width: 25%;
      display: inline-block;
      font-size: 16px;
      cursor: pointer;
      background-color: unset;
      border: none;
      padding: 1px;
      outline: none;
      margin-bottom: 10px;
      img {
        max-width: 100%;
      }
    }
  }
</style>
