<template>
  <div class="questionnaire__button-select mb10 mt10">
    <div class="questionnaire__button-select__caption">
      {{ label }}
    </div>
    <div class="questionnaire__button-select__wrapper">
      <button
        class="questionnaire__button-select__button"
        v-for="option in options"
        :key="option.label || option"
        :class="{ active: option.value === value || option === value}"
        @click="select(option.value || option)"
      >
        {{ option.label || option }}
      </button>
    </div>
    <template v-if="validation">
      <span class="block cl-error h5 mt8" v-if="validation.condition">
        {{ validation.text }}
      </span>
    </template>
    <template v-else-if="validations">
      <span
        v-for="(rule, index) in validations"
        :key="index"
        v-if="rule.condition"
        class="block cl-error h5 mt8"
        data-testid="errorMessage"
      >
        {{ rule.text }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: () => ([])
    },
    name: {
      type: String,
      default: ''
    },
    validation: {
      type: Object,
      required: false,
      default: () => { }
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  methods: {
    select (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>

  $dark: color(accent, $colors-theme, hover);
  $light: color(primary, $colors-background);
  $border: color(primary, $colors-border);
  .questionnaire__button-select {
    position: relative;
    &__wrapper {
      display: flex;
      flex-direction: row;
    }
    & > label {
      font-weight: bold;
      margin-bottom: 10px;
    }
    &__caption {
      font-size: 14px;
      text-align: left;
      margin: 5px 0;
    }
    &__button {
      display: flex;
      padding: 0 10px;
      flex: 1;
      align-content: center;
      justify-content: center;
      height: 40px;
      border: 1px solid $border;
      font-size: 12px;
      background-color: $light;
      color: $dark;
      cursor: pointer;
      outline: none;
      margin-bottom: 10px;
      text-transform: capitalize;
      &:not(:first-of-type):not(:nth-child(2n)) {
        border-left-width: 0;
      }
      &:not(:last-of-type):not(:nth-child(2n)) {
        border-right-width: 0;
      }
      &.active {
        background-color: $border;
        color: $light;
      }
    }
  }
</style>
