<template>
  <div class="w-100">
    <header class="py10 pl20">
      <div class="container">
        <div class="row middle-sm center-xs">
          <h1 class="col-sm-9 mb10 cl-primary weight-300">
            Complete your payment
          </h1>
        </div>
      </div>
    </header>
    <div class="container pb60">
      <div class="row m0 pt15 questionnaire-payment">
        <div class="col-xs-12 col-sm-4">
          <div class="questionnaire-payment__step">
            <div class="questionnaire-payment__step__title">
              Fashion designers gets to work
            </div>
            <div class="questionnaire-payment__step__image -text">
              $20
            </div>
            <div class="questionnaire-payment__step__text">
              The $20 design fee will cover 3 unique style ideas that our designer will create just for you using photorealistic digital images.
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-4">
          <div class="questionnaire-payment__step">
            <div class="questionnaire-payment__step__title">
              your designs arrive
            </div>
            <div class="questionnaire-payment__step__image -invert">
              <md-icon md-src="/assets/questionnaire/dress_icon.svg" class="md-size-3x" />
            </div>
            <div class="questionnaire-payment__step__text">
              Make unlimited revisions of your design, until you are completely happy.
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-4">
          <div class="questionnaire-payment__step">
            <div class="questionnaire-payment__step__title">
              give us feedback & buy
            </div>
            <div class="questionnaire-payment__step__image">
              <img src="/assets/questionnaire/cart.svg" alt="">
            </div>
            <div class="questionnaire-payment__step__text">
              Buy one style or all three, the $20 fee will be credited toward your purchase. The shipping is free in the USA.
            </div>
          </div>
        </div>
        <div class="col-xs-12 mt20 center-xs cl-primary">
          <h3>What is your payment method?</h3>
          <div v-if="errors && errors.length">
            <div>There was an error processing your purchase:</div>
            <div v-for="error in errors" :key="error">
              {{ error }}
            </div>
          </div>
          <md-icon md-src="/assets/loading.svg" class="md-size-5x" v-if="!authToken" />
          <div ref="dropInPlace" />
          <div class="questionnaire-pay__wrapper">
            <button v-if="dropInInstance" class="questionnaire-pay" :disabled="!canSubmit" @click="submitPayment">
              Pay Styling Fee
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import rootStore from '../../../store'

export default {
  data () {
    return {
      agreed: true,
      authToken: '',
      dropInInstance: null,
      paymentIsValid: false,
      patternData: {},
      errors: []
    }
  },
  components: {
  },
  beforeRouteEnter (to, from, next) {
    if (!rootStore.getters['user/isLoggedIn']) {
      next('/')
    } else if (rootStore.getters['questionnaire/currentQuestion'] !== 'END') {
      next('/questionnaire#' + rootStore.getters['questionnaire/currentQuestion'])
    } else {
      next(vm => {
        vm.obtainPaymentToken()
      })
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['question', 'results', 'isComplete']),
    ...mapGetters('user', ['isLoggedIn']),
    canSubmit () {
      return this.paymentIsValid
    }
  },
  methods: {
    ...mapActions('questionnaire', ['obtainToken', 'completePayment', 'newQuestionnaire']),
    submitPayment () {
      if (this.canSubmit) {
        this.dropInInstance.requestPaymentMethod()
          .then((payload) => {
            this.errors = []

            // Send device data and nonce to your server
            // deviceDataInput.value = payload.deviceData
            // nonceInput.value = payload.nonce;
            this.completePayment(payload)
              .then((data) => {
                if (data.success) {
                  this.paymentIsValid = false
                  this.errors = []
                  this.$router.push('/questionnaire/thank-you')
                  this.newQuestionnaire()
                } else {
                  this.errors = data.errors
                  this.paymentIsValid = false
                  this.dropInInstance.clearSelectedPaymentMethod()
                  // trackEvent('funnel', 'transaction-fail')
                }
              })
          })
      }
    },
    obtainPaymentToken () {
      this.errors = []
      this.paymentIsValid = false
      this.agreed = false
      // trackEvent('funnel', 'transaction-start')
      this.obtainToken()
        .then((clientToken) => {
          return import(/* webpackChunkName: "braintree-web-drop-in" */'braintree-web-drop-in')
            .then(({ create }) => {
              this.authToken = clientToken
              return create({
                authorization: clientToken,
                container: this.$refs.dropInPlace,
                paypal: {
                  flow: 'checkout',
                  amount: '14.00',
                  currency: 'USD',
                  buttonStyle: {
                    color: 'blue',
                    shape: 'rect',
                    size: 'medium'
                  }
                }
              })
            })
        })
        .then(instance => {
          this.dropInInstance = instance
          this.listenForDropInEvents()
        })
    },
    listenForDropInEvents () {
      this.dropInInstance.on('paymentMethodRequestable', () => {
        this.errors = []
        this.paymentIsValid = true
      })
      this.dropInInstance.on('noPaymentMethodRequestable', () => {
        this.paymentIsValid = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .questionnaire-pay {
    border-radius: 3px;
    color: color(primary, $colors-background);
    background-color: color(accent, $colors-theme, hover);
    padding: 10px 30px;
    border: none;
    &[disabled] {
      background-color: color(tertiary, $colors-theme);
      cursor: not-allowed;
    }
    &__wrapper {
      width: 100%;
      margin: 10px 0;
      text-align: center;
      box-sizing: border-box;
    }
  }
  .questionnaire-payment__step {
    text-align: center;
    &__title {
      font-size: 20px;
      color: color(primary, $colors-theme);
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin: 10px 0;
    }
    &__image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 10px 0;
      display: inline-flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      color: color(primary, $colors-background);
      background-color: color(accent, $colors-theme, hover);
      &.-invert {
        .md-icon {
          color: color(primary, $colors-background);
        }
      }
      &.-text {
        font-size: 42px;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &__text {
      font-size: 18px;
      color: color(primary, $colors-theme);
      line-height: 125%;
      text-align: center;
      font-weight: 300;
      margin: 10px 0;
    }
  }
</style>
