<template>
  <div class="questionnaire-page">
    <image-selector :options="currentColors" width="6.5%" element-class="-color"
                    v-model="answer" :borders="false" @changed="onToNext"
    />
  </div>
</template>

<script>
import BaseQuestion from '../../../mixins/questionnaire/baseQuestion'
import ImageSelector from './Fields/ImageSelector'
import { mapGetters, mapActions } from 'vuex'
import { colorDistanceFromWhite } from '../../../services/color'

export default {
  name: 'ColorQuestion',
  mixins: [ BaseQuestion ],
  components: { ImageSelector },
  data () {
    return {}
  },
  mounted () {
    if (this.firstQuestionAnswer === 'Design wedding gowns') {
      return this.onToNext()
    }
    this.getColors().then(() => {
      if (!this.currentColors || !this.currentColors.length) {
        this.onToNext()
      }
    })
  },
  computed: {
    ...mapGetters('questionnaire', ['colors', 'answerByQuestionName']),
    choice () {
      return {}
    },
    firstQuestionAnswer () {
      const answer = this.answerByQuestionName('question_1')
      return answer.value || answer
    },
    currentColors () {
      const isWeddingTrj = this.firstQuestionAnswer === 'Design wedding gowns'
      return this.colors
        .filter(m => !isWeddingTrj || m.wedding)
        .sort((m, n) => {
          if (m.sort && n.sort) {
            return +m.sort - +n.sort
          }
          const mWhiteDist = colorDistanceFromWhite(m)
          const nWhiteDist = colorDistanceFromWhite(n)
          if (mWhiteDist && nWhiteDist) {
            return nWhiteDist - mWhiteDist
          } else {
            const mV = +m.image.replace(/.*color_(\d+).*/, '$1')
            const nV = +n.image.replace(/.*color_(\d+).*/, '$1')
            return mV - nV
          }
        })
        .reverse()
    },
    nextQuestion () {
      return this.firstQuestionAnswer === 'Design wedding gowns' ? 'question_price' : 'question_price'
    }
  },
  methods: {
    ...mapActions('questionnaire', ['getColors']),
    setQuestionAnswer () {
      this.setAnswer({
        question: this.question.name.replace(/_.+$/, ''),
        answer: this.choice
      })
    },
    getQuestionAnswer () {
      if (this.question && this.question.name) {
        const answer = this.questionAnswer(this.question.name.replace(/_.+$/, ''))
        if (answer) {
          Object.keys(answer).forEach(key => {
            if (typeof this[key] !== 'undefined') {
              this[key] = answer[key]
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  $mine-shaft: color(mine-shaft);
  $white: color(white);
  .questionnaire-page {
    width: 100%;
  }
  .question {
    &__single-button {
      width: 100%;
      display: block;
      padding: 20px 15%;
      border: 2px solid $mine-shaft;
      font-size: 16px;
      background-color: $white;
      cursor: pointer;
      outline: none;
      margin-bottom: 10px;
    }
    &__single-image {
      width: 25%;
      display: inline-block;
      font-size: 16px;
      cursor: pointer;
      background-color: unset;
      border: none;
      padding: 1px;
      outline: none;
      margin-bottom: 10px;
      img {
        max-width: 100%;
      }
    }
  }
</style>
