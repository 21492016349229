<template>
  <div class="questionnaire__textarea mb10 mt10">
    <div :class="['questionnaire__textarea__caption', value !== '' ? '-has-value' : '']">
      {{ label }}
    </div>
    <textarea
      rows="10"
      cols="50"
      :name="name"
      focus
      v-model="val"
      :placeholder="label"
    />
    <continue-button @click.native="$emit('changed')" />
  </div>
</template>

<script>
import ContinueButton from './ContinueButton'
export default {
  components: {
    ContinueButton
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    units: {
      type: String,
      default: 'cm'
    },
    value: {
      type: [String, Number, Object],
      default: ''
    },
    validation: {
      type: Object,
      default: () => ({ results: {} })
    },
    name: {
      type: String,
      default: ''
    },
    displayUnits: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputFocus: false,
      inputValue: ''
    }
  },
  computed: {
    val: {
      get () {
        return typeof this.value !== 'string' ? '' : this.value
      },
      set (val) {
        return this.$emit('input', val)
      }
    }
  },
  methods: {
  },
  watch: {
    units () {
      this.inputValue = ''
    }
  }
}
</script>

<style lang="scss" scoped>
  $color-tertiary: color(tertiary);
  $color-black: color(black);
  $color-hover: color(tertiary, $colors-background);

  $bg-secondary: color(secondary, $colors-background);
  $color-secondary: color(secondary);
  $color-error: color(error);

  $color-silver: color(silver);
  $color-active: color(secondary);
  $color-white: color(white);

  .questionnaire__textarea {
    position: relative;
    & > label {
      font-weight: bold;
      margin-bottom: 10px;
    }
    &__caption {
      font-size: 14px;
      opacity: 0;
      text-align: left;
      margin-bottom: 10px;
      &.-has-value {
        opacity: 1;
      }
    }
    .error {
      color: $color-error;
      padding-top: 5px;
      display: block;
      font-size: 12px;
    }
    label {
      padding-left: 35px;
      margin-bottom: 5px;
      cursor: pointer;
      font-size: 16px;
      line-height: 30px;
      &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: 22px;
        height: 22px;
        background-color: $color-white;
        border: 1px solid $color-silver;
        cursor: pointer;
      }
    }
    textarea {
      transition: 0.3s all;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 5px;
      border: 1px solid color(primary, $colors-border);
      &::-webkitarea-placeholder {
        color: $color-tertiary;
      }
      &::-moz-placeholder {
        color: $color-tertiary;
      }
      &:hover,
      &:focus {
        outline: none;
        border-color: $color-black;
      }
      background: inherit;
    }
  }
</style>
