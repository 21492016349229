<template>
  <div class="w-100">
    <header class="py35 pl20">
      <div class="container">
        <div class="row middle-sm center-xs cl-primary">
          <div class="col-xs-12 mb15">
            <h1>Measurements</h1>
            <h4>Please, fill out the form below. All fields are required</h4>
          </div>
        </div>
      </div>
    </header>
    <div class="container pb60">
      <div class="row m0 pt15 questionnaire-end">
        <div class="col-md-6 center-xs">
          <img src="/questionnaire/woman_measurements.JPG" alt="">
        </div>
        <div class="col-md-6">
          <!--<div>-->
          <!--<div>Units</div>-->
          <!--<units-selector v-model="units"/>-->
          <!--</div>-->
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="height"
            autocomplete="height"
            v-model="height"
            @blur="$v.height.$touch()"
            placeholder="[A] Height *"
            :validation="{
              condition: !$v.height.required && $v.height.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="neck_circumference"
            autocomplete="neck_circumference"
            v-model="neck_circumference"
            @blur="$v.neck_circumference.$touch()"
            placeholder="[B] Neck Circumference (at the base) *"
            :validation="{
              condition: !$v.neck_circumference.required && $v.neck_circumference.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="bust_circumference"
            autocomplete="bust_circumference"
            v-model="bust_circumference"
            @blur="$v.bust_circumference.$touch()"
            placeholder="[C] Bust Circumference *"
            :validation="{
              condition: !$v.bust_circumference.required && $v.bust_circumference.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="under_bust_circumference"
            autocomplete="under_bust_circumference"
            v-model="under_bust_circumference"
            @blur="$v.under_bust_circumference.$touch()"
            placeholder="[D] Under Bust Circumference *"
            :validation="{
              condition: !$v.under_bust_circumference.required && $v.under_bust_circumference.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="waist_circumference"
            autocomplete="waist_circumference"
            v-model="waist_circumference"
            @blur="$v.waist_circumference.$touch()"
            placeholder="[E] Waist Circumference *"
            :validation="{
              condition: !$v.waist_circumference.required && $v.waist_circumference.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="low_hips_circumference"
            autocomplete="low_hips_circumference"
            v-model="low_hips_circumference"
            @blur="$v.low_hips_circumference.$touch()"
            placeholder="[F] Low Hips Circumference *"
            :validation="{
              condition: !$v.low_hips_circumference.required && $v.low_hips_circumference.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="thigh_circumference"
            autocomplete="thigh_circumference"
            v-model="thigh_circumference"
            @blur="$v.thigh_circumference.$touch()"
            placeholder="[G] Thigh Circumference *"
            :validation="{
              condition: !$v.thigh_circumference.required && $v.thigh_circumference.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="knee_circumference"
            autocomplete="knee_circumference"
            v-model="knee_circumference"
            @blur="$v.knee_circumference.$touch()"
            placeholder="[H] Knee Circumference *"
            :validation="{
              condition: !$v.knee_circumference.required && $v.knee_circumference.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="upper_arm_circumference"
            autocomplete="upper_arm_circumference"
            v-model="upper_arm_circumference"
            @blur="$v.upper_arm_circumference.$touch()"
            placeholder="[I] Upper Arm Circumference *"
            :validation="{
              condition: !$v.upper_arm_circumference.required && $v.upper_arm_circumference.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="wrist_circumference"
            autocomplete="wrist_circumference"
            v-model="wrist_circumference"
            @blur="$v.wrist_circumference.$touch()"
            placeholder="[J] Wrist Circumference *"
            :validation="{
              condition: !$v.wrist_circumference.required && $v.wrist_circumference.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="shoulder_width"
            autocomplete="shoulder_width"
            v-model="shoulder_width"
            @blur="$v.shoulder_width.$touch()"
            placeholder="[K] Shoulder Width *"
            :validation="{
              condition: !$v.shoulder_width.required && $v.shoulder_width.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="bust_height_from_nape"
            autocomplete="bust_height_from_nape"
            v-model="bust_height_from_nape"
            @blur="$v.bust_height_from_nape.$touch()"
            placeholder="[L] Bust Height From Nape *"
            :validation="{
              condition: !$v.bust_height_from_nape.required && $v.bust_height_from_nape.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="front_length_from_nape"
            autocomplete="front_length_from_nape"
            v-model="front_length_from_nape"
            @blur="$v.front_length_from_nape.$touch()"
            placeholder="[M] Front Length From Nape *"
            :validation="{
              condition: !$v.front_length_from_nape.required && $v.front_length_from_nape.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="back_length"
            autocomplete="back_length"
            v-model="back_length"
            @blur="$v.back_length.$touch()"
            placeholder="[N] Back Length *"
            :validation="{
              condition: !$v.back_length.required && $v.back_length.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mt15 mb15"
            type="text"
            name="back_width"
            autocomplete="back_width"
            v-model="back_width"
            @blur="$v.back_width.$touch()"
            placeholder="[O] Back Width *"
            :validation="{
              condition: !$v.back_width.required && $v.back_width.$error,
              text: 'Field is required.'
            }"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 center-xs">
          <h4 class="align-left">
            Please describe your tummy relative to he rest of your body *:
          </h4>
          <div class="fs-medium-small align-left">
            As shown on the image below
          </div>
          <div class="fs-small align-left">
            &nbsp;<br>&nbsp;
          </div>
          <img src="/assets/questionnaire/belly_protuberance.png" alt="" class="mt20 w-100">
          <button-select
            v-model="belly_protuberance"
            :options="['A', 'B', 'C', 'D', 'E', 'F']"
            label=""
            :validation="{
              condition: !$v.belly_protuberance.required && $v.belly_protuberance.$error,
              text: 'Field is required.'
            }"
          />
        </div>
        <div class="col-md-6 center-xs">
          <h4 class="align-left">
            Your buttocks *:
          </h4>
          <div class="fs-medium-small align-left">
            As shown on the image below
          </div>
          <div class="fs-small align-left">
            Stand sideways and look in the mirror. Please note the proportion of your buttocks relative to the rest of your body.
          </div>
          <img src="/assets/questionnaire/buttocks_shape.png" alt="" class="w-100">
          <button-select
            v-model="buttocks_shape"
            :options="['Very Flat', 'Flat', 'Average', 'Curvy', 'Very Curvy']"
            label=""
            :validation="{
              condition: !$v.buttocks_shape.required && $v.buttocks_shape.$error,
              text: 'Field is required.'
            }"
          />
        </div>
        <button-full class="mt15" @click="sendForm">
          Continue
        </button-full>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import BaseInput from '@/components/blocks/Form/BaseInput.vue'
// import UnitsSelector from '../Fields/UnitsSelector.vue'
import ButtonSelect from '../Fields/ButtonSelect'
import { required } from 'vuelidate/lib/validators'
import { trackEvent } from '../../../../utils/analytics'

export default {
  data () {
    return {
      units: 'inch',
      height: '',
      neck_circumference: '',
      bust_circumference: '',
      under_bust_circumference: '',
      waist_circumference: '',
      low_hips_circumference: '',
      thigh_circumference: '',
      knee_circumference: '',
      upper_arm_circumference: '',
      wrist_circumference: '',
      shoulder_width: '',
      bust_height_from_nape: '',
      front_length_from_nape: '',
      back_length: '',
      back_width: '',
      belly_protuberance: '',
      buttocks_shape: ''
    }
  },
  filters: {

  },
  validations: {
    height: {
      required
    },
    neck_circumference: {
      required
    },
    bust_circumference: {
      required
    },
    under_bust_circumference: {
      required
    },
    waist_circumference: {
      required
    },
    low_hips_circumference: {
      required
    },
    thigh_circumference: {
      required
    },
    knee_circumference: {
      required
    },
    upper_arm_circumference: {
      required
    },
    wrist_circumference: {
      required
    },
    shoulder_width: {
      required
    },
    bust_height_from_nape: {
      required
    },
    front_length_from_nape: {
      required
    },
    back_length: {
      required
    },
    back_width: {
      required
    },
    belly_protuberance: {
      required
    },
    buttocks_shape: {
      required
    }
  },
  components: {
    BaseInput,
    ButtonSelect
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (typeof window !== 'undefined') {
        if (to.query.__em) {
          vm.$store.commit('referral/setInvitedState', to.query.__em)
        } else {
          vm.$store.dispatch('referral/loadCachedInviteState')
        }
        if (vm.$store.state.referral.invitedEmail) {
          vm.$store.dispatch('referral/requestInviteInfo', vm.$store.state.referral.invitedEmail).then(() => {
            const info = vm.$store.state.referral.inviteInfo
            if (info && info.customer) {
              vm.name = [info.customer.firstname, info.customer.lastname].join(' ')
              // that is crazy insecure enough
              vm.email = info.customer.email
              vm.phone = info.customer.phone
              // if (info.measurements) {
              //   Object.keys(info.measurements).forEach(key => {
              //     vm[key] = info.measurements[key]
              //   })
              // }
            }
          })
        }
      }
    })
  },
  computed: {
    ...mapGetters('questionnaire', ['question', 'results', 'isComplete']),
    ...mapGetters('user', ['isLoggedIn']),
    canSubmit () {
      return this.paymentIsValid && this.agreed
    },
    results () {
      return {
        height: this.height,
        neck_circumference: this.neck_circumference,
        bust_circumference: this.bust_circumference,
        under_bust_circumference: this.under_bust_circumference,
        waist_circumference: this.waist_circumference,
        low_hips_circumference: this.low_hips_circumference,
        thigh_circumference: this.thigh_circumference,
        knee_circumference: this.knee_circumference,
        upper_arm_circumference: this.upper_arm_circumference,
        wrist_circumference: this.wrist_circumference,
        shoulder_width: this.shoulder_width,
        bust_height_from_nape: this.bust_height_from_nape,
        front_length_from_nape: this.front_length_from_nape,
        back_length: this.back_length,
        back_width: this.back_width,
        belly_protuberance: this.belly_protuberance,
        buttocks_shape: this.buttocks_shape
      }
    }
  },
  methods: {
    ...mapActions('questionnaire', ['obtainToken']),
    sendForm () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        trackEvent('user-action', 'measurements-validation-fail')
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: 'Please fix the validation errors',
          action1: { label: 'Ok' }
        })
      } else {
        this.$store.dispatch('referral/completeMeasurements', this.results)
          .then(() => {
            this.$router.push('/consultation/thank-you')
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  img {
    max-width: 100%;
  }
  .questionnaire-pay {
    border-radius: 3px;
    color: color(primary, $colors-background);
    background-color: color(accent, $colors-theme, hover);
    padding: 10px 30px;
    border: none;
    &[disabled] {
      background-color: color(tertiary, $colors-theme);
      cursor: not-allowed;
    }
    &__wrapper {
      width: 100%;
      text-align: center;
    }
  }
</style>
