import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { trackEvent } from '../../utils/analytics'

export default {
  computed: {
    ...mapGetters('questionnaire', ['answerByQuestionName', 'currentQuestion', 'question', 'nextQuestion']),
    ...mapState('questionnaire', ['answers']),
    isLoggedIn () {
      return this.current !== null
    },
    answer: {
      get () {
        return this.$store.getters['questionnaire/answer']
      },
      set (value) {
        return this.$store.commit('questionnaire/setAnswer', value)
      }
    }
  },
  ready () {
    if (this.$root && this.$root.$el) {
      this.$nextTick(() => {
        if (typeof window !== 'undefined') {
          const question = document.body.querySelector('.scroll-trigger')
          const { x, y } = question.getBoundingClientRect()
          window.scrollTo(x, y)
        }
      })
    }
  },
  watch: {
    currentQuestion (hash) {
      if (hash && hash !== 'END') {
        this.$router.push({ hash })
      }
      if (!hash) {
        this.$store.commit('questionnaire/newQuestionnaire')
        this.$router.push('/questionnaire')
        trackEvent('user-action', 'questionnaire-restart')
      }
    },
    $route (val) {
      const question = val.hash.replace(/^\/?#/, '')
      if (question && question !== this.currentQuestion) {
        this.goToQuestion(question)
        this.$nextTick(() => {
          if (question !== this.currentQuestion) {
            this.$router.push({ hash: this.currentQuestion })
          }
        })
      }
    }
  },
  methods: {
    ...mapMutations('questionnaire', ['setAnswer', 'setAnswerByQuestionName', 'restartQuestionnaire', 'setNext', 'goToQuestion']),
    ...mapActions('questionnaire', ['saveResults', 'commitQuestion', 'getOffers']),
    ...mapActions('notification', ['spawnNotification']),
    onToNext () {
      const next = this.nextQuestion
      if (this.answer) {
        if (this.answer.next && this.answer.next === 'question_mood_custom') {
          return this.$router.push('/consultation/vision')
        }
        if (this.answer && this.answer.name && this.answer.name === 'Halter Neck') {
          this.setAnswerByQuestionName({questionName: 'question_sleeve_type', answer: {'next': 'question_price', 'value': 'Sleeveless'}})
        }
      }
      this.commitQuestion()
      const goToNext = () => {
        this.$emit('next-question')
        trackEvent('user-action', 'questionnaire-next', next)
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0)
        }
      }
      if (next !== 'END') {
        if (next === 'question_8') {
          this.getOffers().then(() => {
            const offers = this.$store.getters['questionnaire/offers']
            if (!offers ||
              !offers.fittingItems ||
              !Object.keys(offers.fittingItems).length ||
              !(offers.fittingItems.Top && offers.fittingItems.Top.length &&
                ((offers.fittingItems.Skirt && offers.fittingItems.Skirt.length) ||
                  (offers.fittingItems.Pants && offers.fittingItems.Pants.length)))
            ) {
              this.$store.commit('questionnaire/newQuestionnaire')
              this.$router.push('/consultation/vision')
              this.$modal.show('dialog', {
                title: 'Sorry',
                text: 'Please excuse us, however, we are still adding design elements to our style library and your selection has not produced any results just yet. Please help us by letting us know your style preferences so we can create a perfect gown for you.',
                buttons: [
                  {
                    class: 'close',
                    title: 'Close'
                  },
                  {
                    class: 'bordered',
                    title: 'Continue'
                  }
                ]
              })
            } else {
              goToNext()
            }
          })
        } else {
          goToNext()
        }
      } else {
        this.$router.push('/questionnaire/offers')
        trackEvent('user-action', 'questionnaire-show-catalog')
      }
    }
  }
}
