var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_vm._m(0),_c('div',{staticClass:"container pb60"},[_c('div',{staticClass:"row m0 pt15 questionnaire-end"},[_vm._m(1),_c('div',{staticClass:"col-md-6"},[_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"height","autocomplete":"height","placeholder":"[A] Height *","validation":{
            condition: !_vm.$v.height.required && _vm.$v.height.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.height.$touch()}},model:{value:(_vm.height),callback:function ($$v) {_vm.height=$$v},expression:"height"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"neck_circumference","autocomplete":"neck_circumference","placeholder":"[B] Neck Circumference (at the base) *","validation":{
            condition: !_vm.$v.neck_circumference.required && _vm.$v.neck_circumference.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.neck_circumference.$touch()}},model:{value:(_vm.neck_circumference),callback:function ($$v) {_vm.neck_circumference=$$v},expression:"neck_circumference"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"bust_circumference","autocomplete":"bust_circumference","placeholder":"[C] Bust Circumference *","validation":{
            condition: !_vm.$v.bust_circumference.required && _vm.$v.bust_circumference.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.bust_circumference.$touch()}},model:{value:(_vm.bust_circumference),callback:function ($$v) {_vm.bust_circumference=$$v},expression:"bust_circumference"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"under_bust_circumference","autocomplete":"under_bust_circumference","placeholder":"[D] Under Bust Circumference *","validation":{
            condition: !_vm.$v.under_bust_circumference.required && _vm.$v.under_bust_circumference.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.under_bust_circumference.$touch()}},model:{value:(_vm.under_bust_circumference),callback:function ($$v) {_vm.under_bust_circumference=$$v},expression:"under_bust_circumference"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"waist_circumference","autocomplete":"waist_circumference","placeholder":"[E] Waist Circumference *","validation":{
            condition: !_vm.$v.waist_circumference.required && _vm.$v.waist_circumference.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.waist_circumference.$touch()}},model:{value:(_vm.waist_circumference),callback:function ($$v) {_vm.waist_circumference=$$v},expression:"waist_circumference"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"low_hips_circumference","autocomplete":"low_hips_circumference","placeholder":"[F] Low Hips Circumference *","validation":{
            condition: !_vm.$v.low_hips_circumference.required && _vm.$v.low_hips_circumference.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.low_hips_circumference.$touch()}},model:{value:(_vm.low_hips_circumference),callback:function ($$v) {_vm.low_hips_circumference=$$v},expression:"low_hips_circumference"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"thigh_circumference","autocomplete":"thigh_circumference","placeholder":"[G] Thigh Circumference *","validation":{
            condition: !_vm.$v.thigh_circumference.required && _vm.$v.thigh_circumference.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.thigh_circumference.$touch()}},model:{value:(_vm.thigh_circumference),callback:function ($$v) {_vm.thigh_circumference=$$v},expression:"thigh_circumference"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"knee_circumference","autocomplete":"knee_circumference","placeholder":"[H] Knee Circumference *","validation":{
            condition: !_vm.$v.knee_circumference.required && _vm.$v.knee_circumference.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.knee_circumference.$touch()}},model:{value:(_vm.knee_circumference),callback:function ($$v) {_vm.knee_circumference=$$v},expression:"knee_circumference"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"upper_arm_circumference","autocomplete":"upper_arm_circumference","placeholder":"[I] Upper Arm Circumference *","validation":{
            condition: !_vm.$v.upper_arm_circumference.required && _vm.$v.upper_arm_circumference.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.upper_arm_circumference.$touch()}},model:{value:(_vm.upper_arm_circumference),callback:function ($$v) {_vm.upper_arm_circumference=$$v},expression:"upper_arm_circumference"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"wrist_circumference","autocomplete":"wrist_circumference","placeholder":"[J] Wrist Circumference *","validation":{
            condition: !_vm.$v.wrist_circumference.required && _vm.$v.wrist_circumference.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.wrist_circumference.$touch()}},model:{value:(_vm.wrist_circumference),callback:function ($$v) {_vm.wrist_circumference=$$v},expression:"wrist_circumference"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"shoulder_width","autocomplete":"shoulder_width","placeholder":"[K] Shoulder Width *","validation":{
            condition: !_vm.$v.shoulder_width.required && _vm.$v.shoulder_width.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.shoulder_width.$touch()}},model:{value:(_vm.shoulder_width),callback:function ($$v) {_vm.shoulder_width=$$v},expression:"shoulder_width"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"bust_height_from_nape","autocomplete":"bust_height_from_nape","placeholder":"[L] Bust Height From Nape *","validation":{
            condition: !_vm.$v.bust_height_from_nape.required && _vm.$v.bust_height_from_nape.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.bust_height_from_nape.$touch()}},model:{value:(_vm.bust_height_from_nape),callback:function ($$v) {_vm.bust_height_from_nape=$$v},expression:"bust_height_from_nape"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"front_length_from_nape","autocomplete":"front_length_from_nape","placeholder":"[M] Front Length From Nape *","validation":{
            condition: !_vm.$v.front_length_from_nape.required && _vm.$v.front_length_from_nape.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.front_length_from_nape.$touch()}},model:{value:(_vm.front_length_from_nape),callback:function ($$v) {_vm.front_length_from_nape=$$v},expression:"front_length_from_nape"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"back_length","autocomplete":"back_length","placeholder":"[N] Back Length *","validation":{
            condition: !_vm.$v.back_length.required && _vm.$v.back_length.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.back_length.$touch()}},model:{value:(_vm.back_length),callback:function ($$v) {_vm.back_length=$$v},expression:"back_length"}}),_c('base-input',{staticClass:"col-xs-12 mt15 mb15",attrs:{"type":"text","name":"back_width","autocomplete":"back_width","placeholder":"[O] Back Width *","validation":{
            condition: !_vm.$v.back_width.required && _vm.$v.back_width.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.back_width.$touch()}},model:{value:(_vm.back_width),callback:function ($$v) {_vm.back_width=$$v},expression:"back_width"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 center-xs"},[_c('h4',{staticClass:"align-left"},[_vm._v("\n          Please describe your tummy relative to he rest of your body *:\n        ")]),_c('div',{staticClass:"fs-medium-small align-left"},[_vm._v("\n          As shown on the image below\n        ")]),_vm._m(2),_c('img',{staticClass:"mt20 w-100",attrs:{"src":"/assets/questionnaire/belly_protuberance.png","alt":""}}),_c('button-select',{attrs:{"options":['A', 'B', 'C', 'D', 'E', 'F'],"label":"","validation":{
            condition: !_vm.$v.belly_protuberance.required && _vm.$v.belly_protuberance.$error,
            text: 'Field is required.'
          }},model:{value:(_vm.belly_protuberance),callback:function ($$v) {_vm.belly_protuberance=$$v},expression:"belly_protuberance"}})],1),_c('div',{staticClass:"col-md-6 center-xs"},[_c('h4',{staticClass:"align-left"},[_vm._v("\n          Your buttocks *:\n        ")]),_c('div',{staticClass:"fs-medium-small align-left"},[_vm._v("\n          As shown on the image below\n        ")]),_c('div',{staticClass:"fs-small align-left"},[_vm._v("\n          Stand sideways and look in the mirror. Please note the proportion of your buttocks relative to the rest of your body.\n        ")]),_c('img',{staticClass:"w-100",attrs:{"src":"/assets/questionnaire/buttocks_shape.png","alt":""}}),_c('button-select',{attrs:{"options":['Very Flat', 'Flat', 'Average', 'Curvy', 'Very Curvy'],"label":"","validation":{
            condition: !_vm.$v.buttocks_shape.required && _vm.$v.buttocks_shape.$error,
            text: 'Field is required.'
          }},model:{value:(_vm.buttocks_shape),callback:function ($$v) {_vm.buttocks_shape=$$v},expression:"buttocks_shape"}})],1),_c('button-full',{staticClass:"mt15",on:{"click":_vm.sendForm}},[_vm._v("\n        Continue\n      ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"py35 pl20"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row middle-sm center-xs cl-primary"},[_c('div',{staticClass:"col-xs-12 mb15"},[_c('h1',[_vm._v("Measurements")]),_c('h4',[_vm._v("Please, fill out the form below. All fields are required")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 center-xs"},[_c('img',{attrs:{"src":"/questionnaire/woman_measurements.JPG","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fs-small align-left"},[_vm._v("\n           "),_c('br'),_vm._v(" \n        ")])
}]

export { render, staticRenderFns }