<template>
  <div class="w-100">
    <header class="py35 pl20">
      <div class="container">
        <div class="row middle-sm center-xs cl-primary">
          <h1 class="col-sm-9 mb10">
            Thank you for completing your Style ID!
          </h1>
          <h4>We will send you your first 3 designs 24-72 hours from now. Please stay tuned.</h4>
        </div>
      </div>
    </header>
    <div class="container pb60">
      <div class="row m0 pt15 questionnaire-end">
        <div class="col-xs-12 col-md-8 col-md-offset-2">
          <p>
            Your answer has been sent to our designers. Await an email from them, or write one yourself - in case if you missed something. Here is our email address: <a class="links" href="mailto:help@couturme.com">help@couturme.com</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import rootStore from '../../../store'

export default {
  data () {
    return {
      agreed: true,
      authToken: '',
      dropInInstance: null,
      paymentIsValid: false,
      patternData: {},
      errors: []
    }
  },
  components: {
  },
  beforeRouteEnter (to, from, next) {
    if (!rootStore.getters['user/isLoggedIn']) {
      next('/')
    } else if (!rootStore.getters['questionnaire/isComplete']) {
      next('/questionnaire')
    } else {
      next(vm => {
        vm.obtainPaymentToken()
      })
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['question', 'results', 'isComplete']),
    ...mapGetters('user', ['isLoggedIn']),
    canSubmit () {
      return this.paymentIsValid && this.agreed
    }
  },
  methods: {
    ...mapActions('questionnaire', ['obtainToken']),
    submitPayment () {
      if (this.canSubmit) {
        this.dropInInstance.requestPaymentMethod()
          .then((payload) => {
            this.errors = []

            // Send device data and nonce to your server
            // deviceDataInput.value = payload.deviceData
            // nonceInput.value = payload.nonce;
            this.buyPattern({
              ...this.patternData,
              ...payload
            })
              .then(({ data }) => {
                if (data.success) {
                  this.paymentIsValid = false
                  this.errors = []
                  // trackTransaction({
                  //   'id': data.id,
                  //   'affiliation': this.patternData.patternName,
                  //   'revenue': 14,
                  //   'shipping': 0,
                  //   'tax': 0
                  // })
                } else {
                  this.errors = data.errors
                  this.dropInInstance.clearSelectedPaymentMethod()
                  // trackEvent('funnel', 'transaction-fail')
                }
              })
          })
      }
    },
    obtainPaymentToken () {
      this.errors = []
      this.paymentIsValid = false
      this.agreed = false
      // trackEvent('funnel', 'transaction-start')
      this.obtainToken()
        .then((clientToken) => {
          return import(/* webpackChunkName: "braintree-web-drop-in" */'braintree-web-drop-in')
            .then(({ create }) => {
              this.authToken = clientToken
              return create({
                authorization: clientToken,
                container: this.$refs.dropInPlace,
                paypal: {
                  flow: 'checkout',
                  amount: '14.00',
                  currency: 'USD',
                  buttonStyle: {
                    color: 'blue',
                    shape: 'rect',
                    size: 'medium'
                  }
                }
              })
            })
        })
        .then(instance => {
          this.dropInInstance = instance
          this.listenForDropInEvents()
        })
    },
    listenForDropInEvents () {
      this.dropInInstance.on('paymentMethodRequestable', () => {
        this.errors = []
        this.paymentIsValid = true
      })
      this.dropInInstance.on('noPaymentMethodRequestable', () => {
        this.paymentIsValid = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .questionnaire-pay {
    border-radius: 3px;
    color: color(primary, $colors-background);
    background-color: color(accent, $colors-theme, hover);
    padding: 10px 30px;
    border: none;
    &[disabled] {
      background-color: color(tertiary, $colors-theme);
      cursor: not-allowed;
    }
    &__wrapper {
      width: 100%;
      text-align: center;
    }
  }
</style>
