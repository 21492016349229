<template>
  <div class="questionnaire__cloth-selector mt20">
    <div class="questionnaire__cloth-selector__title">
      <div class="h4 weight-400">
        Please, select your favourite styles of {{ type === 'Embroidery' ? 'Appliqué' : type | lowercase }}
      </div>
      <div class="links">
        <div class="link-grey" :class="{'-selected': allSelected}" @click="handleSelectAll">
          All
        </div>
        <div class="link-grey" :class="{'-selected': noneSelected}" @click="handleSelectNone">
          None
        </div>
      </div>
    </div>
    <div class="questionnaire__cloth-selector__row" @mousedown="buttonPressed = true" @mouseup="buttonPressed = false">
      <cloth-part-render
        :height="size.h"
        @notify-size="handleSizeChange"
        :class="[{'-selected': isSelected(choice), '-bordered': borders, '-zoom': !choice.next}, elementClass]"
        :key="choice.airtableId || choice"
        :width="renderWidth"
        v-for="choice in options" @click="select(choice)"
        :item="choice"
        :input-on-mouseover="buttonPressed"
      />
    </div>
  </div>
</template>
<script>
import ClothPartRender from './ClothPartRender'
export default {
  components: { ClothPartRender },
  filters: {
    lowercase (val) {
      return `${val}`.toLowerCase().replace(/ss$/, 's')
    }
  },
  props: {
    value: {
      type: [Array, Object, String],
      default: () => ([])
    },
    options: {
      type: Array,
      default: () => ([])
    },
    hasNext: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '10%'
    },
    type: {
      type: String,
      default: ''
    },
    compareKey: {
      type: String,
      default: 'airtableId'
    },
    borders: {
      type: Boolean,
      default: true
    },
    elementClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      buttonPressed: false,
      size: {
        w: 100,
        h: 0
      }
    }
  },
  computed: {
    selected () {
      if (this.value && Array.isArray(this.value)) {
        return this.value
      }
      if (this.value && this.value['0']) {
        return Object.values(this.value)
      }
      return []
    },
    rowStyle () {
      if (`${this.width}`.endsWith('%')) {
        const count = ~~(100 / +this.width.replace(/[^\d.]/, ''))
        return {
          'grid-template-columns': `repeat(${count}, ${100 / count}fr)`
        }
      }
      return {}
    },
    itemWidth () {
      if (!`${this.width}`.endsWith('%')) {
        return this.width
      }
      return null
    },
    renderWidth () {
      if (this.type === 'Sleeve') {
        return this.size.w / 2
      }
      return this.size.w
    },
    allSelected () {
      return this.selected.length === this.options.length
    },
    noneSelected () {
      return !this.selected.length
    }
  },
  methods: {
    isSelected (choice) {
      return !!this.selected.find(this.compare(choice))
    },
    handleSizeChange ({ w, h }) {
      if (this.size.w < w) this.size.w = w
      if (this.size.h < h) this.size.h = h
    },
    compare (choice) {
      const compareKey = this.compareKey
      return option => {
        if (compareKey) {
          return option[compareKey] === choice[compareKey]
        }
        if (Object.keys(choice).length === 1) {
          const key = Object.keys(choice)[0]
          return option[key] === choice[key]
        }
        if (choice.image) {
          return option.image === choice.image
        }
        return JSON.stringify(option) === JSON.stringify(choice)
      }
    },
    handleSelectAll () {
      this.$emit('input', this.type, this.options)
    },
    select (choice) {
      if (choice.next) {
        this.$emit('input', this.type, choice)
        this.$emit('changed')
      } else {
        const arrayValue = Array.isArray(this.value) ? this.value : []
        if (choice.similar) {
          if (!this.selected.find(this.compare(choice))) {
            this.$emit('input', this.type, [...arrayValue, ...choice.similar])
          } else {
            const newValue = choice.similar.reduce((newValue, choice) => {
              const index = this.selected.findIndex(this.compare(choice))
              return index > -1 ? [
                ...newValue.slice(0, index),
                ...newValue.slice(index + 1)
              ] : newValue
            }, arrayValue)
            this.$emit('input', this.type, newValue)
          }
        } else {
          if (!this.selected.find(this.compare(choice))) {
            this.$emit('input', this.type, [...arrayValue, choice])
          } else {
            const index = this.selected.findIndex(this.compare(choice))
            this.$emit('input', this.type, [
              ...arrayValue.slice(0, index),
              ...arrayValue.slice(index + 1)
            ])
          }
        }
      }
    },
    handleSelectNone () {
      this.$emit('input', this.type, [])
    }
  }
}
</script>
<style lang="scss" scoped>
  .floating {
    @include smaller-than(tablet) {
      border: 1px solid color(primary, $colors-border);
      z-index: 100;
      position: fixed;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .questionnaire__cloth-selector {
    position: relative;
    &__title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      justify-items: center;
      align-items: center;
      .link-grey {
        cursor: pointer;
        display: inline-block;
        margin: 10px;
        padding: 2px 5px;
        &:hover {
          text-decoration: underline;
        }
        &.-selected {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
    &__row {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      @include smaller-than(tablet) {
        display: block !important;
        text-align: center;
      }
    }
    &__image {
      display: inline-flex;
      align-content: center;
      justify-content: center;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
      font-size: 16px;
      cursor: pointer;
      background-color: unset;
      margin: 1px;
      outline: none;
      border: none;
      padding: 5px;
      box-sizing: border-box;
      img {
        max-width: 100%;
      }

      &.-image {
        @include smaller-than(tablet) {
          width: 100% !important;
        }
      }
      &.-color {
        min-height: 100px;
        @include smaller-than(tablet) {
          width: 19.5% !important;
        }
      }
      &.-fabric {
        @include smaller-than(tablet) {
          width: 49% !important;
        }
      }
      &.-sleeve {
        @include smaller-than(tablet) {
          width: 32% !important;
          float:left;
        }
      }
      &.-neckline, &.-skirt {
        @include smaller-than(tablet) {
          width: 32% !important;
        }
      }

      &.-color:after {
        bottom: 15px;
        right: 12px;
      }
      &.-bordered {
        padding: 0;
        border: 1px solid color(territary, $colors-theme);
        border-radius: 3px;
        &.-selected {
          border-color: color(primary, $colors-theme);
          box-shadow: none;
        }
      }
      &.-selected {
        position: relative;

        &:after {
          position: absolute;
          bottom: 15px;
          right: 12px;
          content: '✓';
          border-radius: 50%;
          width: 20px;
          height: 20px;
          font-size: 14px;
          background-color: color(accent, $colors-theme, hover);
          border: 1px solid;
          color: color(primary, $colors-background);
          @include whiteframe()
        }

        &.-image:after {
          bottom: 20px;
          right: 20px;
        }

        &.-color:after {
          bottom: 15px;
          right: 12px;
        }
      }
    }
  }
</style>
