<template>
  <div class="questionnaire__radio-buttons mb10 mt10">
    <div class="questionnaire__radio-buttons__caption" v-if="label">
      {{ label }}
    </div>
    <div class="questionnaire__radio-buttons__wrapper">
      <button
        class="questionnaire__radio-buttons__button"
        :class="{'-current': isSelected(choice)}"
        :key="choice.value || choice"
        v-for="choice in options" @click="select(choice)"
      >
        {{ choice.text || choice.value || choice }}
      </button>
    </div>
    <continue-button v-if="!multiple && value && hasNext" @click="skip" />
  </div>
</template>

<script>
import ContinueButton from './ContinueButton.vue'

export default {
  components: {
    ContinueButton
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Object, Array],
      default: ''
    },
    options: {
      type: Array,
      default: () => ([])
    },
    multiple: {
      type: Boolean,
      default: false
    },
    hasNext: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    selected () {
      if (this.value && this.value.length && Array.isArray(this.value)) {
        return this.value
      }
      return []
    }
  },
  methods: {
    isSelected (choice) {
      const value = this.value.value || this.value
      if (this.multiple) {
        return this.selected.includes(choice)
      }
      if (choice.value) {
        return choice.value === value
      }
      return value === choice
    },
    skip () {
      const value = this.value.value || this.value
      const currentChoice = this.options.find(c => c.value === value || c === value)
      if (currentChoice) {
        this.$emit('input', currentChoice)
        this.$emit('changed')
      }
    },
    select (value) {
      if (!this.multiple) {
        this.$emit('input', value)
        this.$emit('changed')
      } else {
        if (!this.selected.includes(value)) {
          this.$emit('input', [...(this.value || []), value])
        } else {
          const index = this.selected.indexOf(value)
          this.$emit('input', [
            ...(this.value || []).slice(0, index),
            ...(this.value || []).slice(index + 1)
          ])
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  $dark: color(primary, $colors-theme);
  $light: color(primary, $colors-background);
  $light-text: lighten(color(secondary, $colors-theme, hover), 25%);
  $border: color(primary, $colors-border);
  .questionnaire__radio-buttons {
    position: relative;
    &__wrapper {
      display: flex;
      flex-direction: column;
    }
    & > label {
      font-weight: bold;
      margin-bottom: 10px;
    }
    &__caption {
      font-size: 14px;
      text-align: left;
      margin: 5px 0;
    }
    &__button {
      width: 100%;
      display: block;
      padding: 10px 5px;
      color: $light-text;
      border: 1px solid $border;
      border-radius: 3px;
      font-size: 16px;
      background-color: $light;
      cursor: pointer;
      outline: none;
      margin-bottom: 10px;
      &.-current {
        border-color: $dark;
        color: $dark;
      }
    }
  }
</style>
