<template>
  <div class="questionnaire__text-input mb10 mt10">
    <div :class="['questionnaire__text-input__caption', value !== '' ? '-has-value' : '']">
      {{ label }}
    </div>
    <input
      class="
            py10 w-100 border-box brdr-none brdr-bottom-1
            brdr-cl-primary h5 sans-serif
          "
      type="text"
      :name="name"
      focus
      @blur="handleBlur" v-model="val" @focus="handleFocusIn" @keyup="handleKeyUp"
      :placeholder="label"
    >
    <span class="units_short" v-if="displayUnits">{{ unitsShort }}</span>
  </div>
</template>

<script>
import { cmToRightUnits, unitsInputToCm, inputNumericValue } from '../../../../../src/utils'
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    units: {
      type: String,
      default: 'cm'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    validation: {
      type: Object,
      default: () => ({ results: {} })
    },
    name: {
      type: String,
      default: ''
    },
    displayUnits: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputFocus: false,
      inputValue: ''
    }
  },
  computed: {
    unitsShort () {
      return this.units.toLowerCase() === 'inch'
        ? 'in'
        : 'cm'
    },
    commitedValue () {
      return this.value
    },
    val: {
      get () {
        if (this.inputFocus && this.inputValue) {
          return this.inputValue
        }
        if (this.value !== '-' && this.value !== '') {
          return cmToRightUnits(this.value, this.units)
        } else {
          return this.value
        }
      },
      set (val) {
        const value = inputNumericValue(val)
        this.inputValue = val
        if (!isNaN(value)) {
          let cmValue = value
          if (!cmValue) {
            cmValue = 0
          }
          let val = unitsInputToCm(value, this.units)
          return this.$emit('input', val)
        }
      }
    }
  },
  methods: {
    handleFocusIn ($event) {
      this.inputValue = +this.val
      if ($event && $event.target) {
        if (+$event.target.value === 0) {
          $event.target.value = ''
          this.inputValue = ''
        }
      }
      this.inputFocus = true
    },
    handleKeyUp ($event) {
      if ($event && $event.target) {
        if ($event.keyCode === 13) {
          $event.target.blur()
        }
      }
    },
    handleBlur ($event) {
      if ($event && $event.target && $event.target.value === '') {
        $event.target.value = '0'
      }
      this.inputFocus = false
      this.inputValue = ''
    }
  },
  watch: {
    units () {
      this.inputValue = ''
    }
  }
}
</script>

<style lang="scss" scoped>
  $color-tertiary: color(tertiary);
  $color-black: color(black);
  $color-hover: color(tertiary, $colors-background);

  $bg-secondary: color(secondary, $colors-background);
  $color-secondary: color(secondary);
  $color-error: color(error);

  $color-silver: color(silver);
  $color-active: color(secondary);
  $color-white: color(white);

  .questionnaire__text-input {
    position: relative;
    & > label {
      font-weight: bold;
      margin-bottom: 10px;
    }
    &__caption {
      font-size: 14px;
      opacity: 0;
      text-align: left;
      &.-has-value {
        opacity: 1;
      }
    }
    .error {
      color: $color-error;
      padding-top: 5px;
      display: block;
      font-size: 12px;
    }
    label {
      padding-left: 35px;
      margin-bottom: 5px;
      cursor: pointer;
      font-size: 16px;
      line-height: 30px;
      &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: 22px;
        height: 22px;
        background-color: $color-white;
        border: 1px solid $color-silver;
        cursor: pointer;
      }
    }
    input[type='text'] {
      transition: 0.3s all;
      &::-webkit-input-placeholder {
        color: $color-tertiary;
      }
      &::-moz-placeholder {
        color: $color-tertiary;
      }
      &:hover,
      &:focus {
        outline: none;
        border-color: $color-black;
      }
      background: inherit;
      & + .units_short {
        font-size: 12px;
        padding: 0 5px;
        color: color(tertiary);
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
        cursor: pointer;
        position: absolute;
        right: 5px;
        bottom: 10px;
      }
    }
    input[type='radio'], input[type='checkbox']  {
      position: absolute;
      top: 3px;
      left: 0;
      &:checked + label {
        &:before {
          background-color: $color-silver;
          border-color: $color-silver;
          cursor: pointer;
        }
        &:after {
          content: '';
          position: absolute;
          top: 9px;
          left: 5px;
          width: 11px;
          height: 5px;
          border: 3px solid $color-white;
          border-top: none;
          border-right: none;
          background-color: $color-silver;
          transform: rotate(-45deg);
        }
      }
      &:hover,
      &:focus {
        + label {
          &:before {
            border-color: $color-active;
          }
        }
      }
      &:disabled + label {
        cursor: not-allowed;
        &:hover,
        &:focus {
          &:before {
            border-color: $color-silver;
            cursor: not-allowed;
          }
        }
      }
    }
  }
</style>
