<template>
  <div class="question">
    <div>
      <div class="center-xs">
        <label for="" class="center-xs">
          <input type="checkbox" v-model="skipMeasurements">
          I dont have a measurements on me right now
        </label>
      </div>
      <div class="row" v-if="!skipMeasurements">
        <div class="col-xs-12">
          <text-input label="How tall are you?" v-model="height" />
        </div>
      </div>
      <div class="row" v-if="!skipMeasurements">
        <div class="col-xs-12">
          <text-input label="What is your weight?" v-model="weight" />
        </div>
      </div>
      <div class="row" v-if="!skipMeasurements">
        <div class="col-xs-12">
          <h4>What sizes do you typically wear?</h4>
          <text-input label="Dresses" v-model="dresses_size" />
          <text-input label="Knit Tops" v-model="knit_tops_size" />
          <text-input label="Shirts" v-model="shirts_size" />
          <text-input label="Skirts" v-model="skirts_size" />
          <text-input label="Bra Size?" v-model="bra_size" />
        </div>
        <div class="col-xs-12">
          <h4>How would you characterize your proportions?</h4>
          <button-select
            v-model="arms_proportions"
            :options="['Short', 'Average', 'Long']"
            label="Arms"
          />
          <button-select
            v-model="shoulders_proportions"
            :options="['Narrow', 'Average', 'Wide']"
            label="Shoulders"
          />
          <button-select
            v-model="torso_proportions"
            :options="['Short', 'Average', 'Long']"
            label="Torso"
          />
          <button-select
            v-model="hips_proportions"
            :options="['Narrow', 'average', 'broad']"
            label="Hips"
          />
          <button-select
            v-model="legs_proportions"
            :options="['Short', 'Average', 'long']"
            label="Legs"
          />
        </div>
      </div>
      <div class="row" v-if="!skipMeasurements">
        <div class="col-xs-12 center-xs">
          <h4 class="align-left">
            Please describe your tummy relative to he rest of your body:
          </h4>
          <div class="fs-medium-small align-left">
            As shown on the image below
          </div>
          <img src="/questionnaire/belly_protuberance.png" alt="" class="mt20">
          <button-select
            v-model="tummy"
            :options="['A', 'B', 'C', 'D', 'E', 'F']"
            label=""
          />
        </div>
        <div class="col-xs-12 center-xs">
          <h4 class="align-left">
            Your buttocks:
          </h4>
          <div class="fs-medium-small align-left">
            As shown on the image below
          </div>
          <div class="fs-small align-left">
            Stand sideways and look in the mirror. Please note the proportion of your buttocks relative to the rest of your body.
          </div>
          <img src="/questionnaire/buttocks_shape.png" alt="">
          <button-select
            v-model="buttocks"
            :options="['Very Flat', 'Flat', 'Average', 'Curvy', 'Very Curvy']"
            label=""
          />
        </div>
      </div>
      <div class="row mt20">
        <div class="col-xs-12">
          <div class="center-xs">
            <span>Optional:</span><span class="h4 ml10 bold">Upload an image of your figure; front, back, side (you can crop your face)</span>
          </div>
        </div>
        <div class="col-xs-12 image-upload">
          <h5 class="center-xs">
            Front
          </h5>
          <upload-image v-model="body_picture_front" :has-next="false" />
        </div>
        <div class="col-xs-12 image-upload">
          <h5 class="center-xs">
            Back
          </h5>
          <upload-image v-model="body_picture_back" :has-next="false" />
        </div>
        <div class="col-xs-12 image-upload">
          <h5 class="center-xs">
            Side
          </h5>
          <upload-image v-model="body_picture_side" :has-next="false" />
        </div>
      </div>
      <continue-button @click="onToNext" />
    </div>
  </div>
</template>

<script>
import TextInput from './Fields/Input.vue'
import BaseQuestion from '../../../mixins/questionnaire/baseQuestion'
import ButtonSelect from './Fields/ButtonSelect.vue'
import UploadImage from './Fields/UploadImage.vue'
import ContinueButton from './Fields/ContinueButton.vue'

export default {
  mixins: [BaseQuestion],
  components: {
    TextInput,
    ButtonSelect,
    UploadImage,
    ContinueButton
  },
  data () {
    return {
      // skipMeasurements: '',
      // height: '',
      // weight: '',
      // dresses_size: '',
      // knit_tops_size: '',
      // shirts_size: '',
      // skirts_size: '',
      // bra_size: '',
      // arms_proportions: '',
      // shoulders_proportions: '',
      // torso_proportions: '',
      // hips_proportions: '',
      // legs_proportions: '',
      // buttocks: '',
      // tummy: '',
      // body_picture: {
      //   front: '',
      //   back: '',
      //   side: ''
      // }
    }
  },
  computed: {
    skipMeasurements: {
      get () {
        return this.answer ? this.answer.skipMeasurements : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), skipMeasurements: val }
      }
    },
    height: {
      get () {
        return this.answer ? this.answer.height : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), height: val }
      }
    },
    weight: {
      get () {
        return this.answer ? this.answer.weight : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), weight: val }
      }
    },
    dresses_size: {
      get () {
        return this.answer ? this.answer.dresses_size : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), dresses_size: val }
      }
    },
    knit_tops_size: {
      get () {
        return this.answer ? this.answer.knit_tops_size : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), knit_tops_size: val }
      }
    },
    shirts_size: {
      get () {
        return this.answer ? this.answer.shirts_size : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), shirts_size: val }
      }
    },
    skirts_size: {
      get () {
        return this.answer ? this.answer.skirts_size : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), skirts_size: val }
      }
    },
    bra_size: {
      get () {
        return this.answer ? this.answer.bra_size : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), bra_size: val }
      }
    },
    arms_proportions: {
      get () {
        return this.answer ? this.answer.arms_proportions : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), arms_proportions: val }
      }
    },
    shoulders_proportions: {
      get () {
        return this.answer ? this.answer.shoulders_proportions : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), shoulders_proportions: val }
      }
    },
    torso_proportions: {
      get () {
        return this.answer ? this.answer.torso_proportions : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), torso_proportions: val }
      }
    },
    hips_proportions: {
      get () {
        return this.answer ? this.answer.hips_proportions : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), hips_proportions: val }
      }
    },
    legs_proportions: {
      get () {
        return this.answer ? this.answer.legs_proportions : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), legs_proportions: val }
      }
    },
    buttocks: {
      get () {
        return this.answer ? this.answer.buttocks : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), buttocks: val }
      }
    },
    tummy: {
      get () {
        return this.answer ? this.answer.tummy : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), tummy: val }
      }
    },
    body_picture_front: {
      get () {
        return this.answer.body_picture ? this.answer.body_picture.front : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), body_picture: { ...(this.answer.body_picture || {}), front: val } }
      }
    },
    body_picture_back: {
      get () {
        return this.answer.body_picture ? this.answer.body_picture.back : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), body_picture: { ...(this.answer.body_picture || {}), back: val } }
      }
    },
    body_picture_side: {
      get () {
        return this.answer.body_picture ? this.answer.body_picture.side : ''
      },
      set (val) {
        this.answer = { ...(this.answer || {}), body_picture: { ...(this.answer.body_picture || {}), side: val } }
      }
    }
  },
  methods: {
    setQuestionAnswer () {
      this.setAnswer({
        question: this.question.name.replace(/_.+$/, ''),
        answer: this.choice
      })
    },
    getQuestionAnswer () {
      if (this.question && this.question.name) {
        const answer = this.questionAnswer(this.question.name.replace(/_.+$/, ''))
        if (answer) {
          Object.keys(answer).forEach(key => {
            if (typeof this[key] !== 'undefined') {
              this[key] = answer[key]
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$mine-shaft: color(mine-shaft);
$white: color(white);

.question {
  width: 100%;
  @include respond-to(tablet) {
    width: 50%;
    margin: 0 auto;
  }
  img {
    max-width: 100%;
  }
  &__button-next {
    display: block;
    padding: 20px 15%;
    border: 2px solid $mine-shaft;
    font-size: 16px;
    background-color: $white;
    cursor: pointer;
    outline: none;
    margin: 10px auto;
  }
}
</style>
