<template>
  <div class="accordion">
    <dt class="tab" @click="handleTabExpand">
      <span class="tab-heading" :class="{'-bold': open}">{{ name }}</span>
      <span class="icon icon-arrow-right-grey"/>
    </dt>
    <dd class="tab-container" :class="{'-open': open}">
      <slot />
    </dd>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    handleTabExpand () {
      this.open = !this.open
      this.$emit('opened', this.open)
    }
  }
}
</script>
<style lang="scss" scoped>
  .accordion {
    dt {
      cursor: pointer;
      padding: 15px 12px 15px 0;
      border-top: 1px solid color(primary, $colors-border);
      .tab-heading {
        font-size: 18px;
        color: color(secondary);
        font-weight: 400;
        &.-bold {
          font-weight: bold;
        }
      }
    }
    dd {
      display: none;
      overflow: hidden;
      /*max-height: 170px;*/
      margin-bottom: 15px;
      margin-left: 0;
      height: fit-content;
      max-height: none;
      .pa1 {
        margin: 0;
      }
      .pa1-black {
        margin: 0;
      }
      .service-section {
        p {
          margin: 0;
        }
        ul li {
          margin: 0;
        }
      }
      .service-table td {
        margin: 0;
      }
      &.-open {
        display: block;
      }
    }
    &.accordion-open {
      dt.current {
        .tab-heading {
          color: color(primary);
        }
        .icon-arrow-right-grey {
          transform: rotate(90deg);
        }
      }
    }
    .mCSB_scrollTools_vertical {
      margin-right: 8px;
    }
    @media(min-width: 771px) {
      dt {
        padding: 12px 12px 12px 0;
      }
      .mCSB_scrollTools_vertical {
        margin-right: 11px;
      }
      .tab {
        text-transform: none;
      }
      .icon-arrow-right-grey {
        width: 14px;
        height: 14px;
      }
    }
    @media(min-width: 599px) {
      margin: 5px 0;
      dt {
        .tab-heading {
          text-transform: none;
          font-size: 14px;
        }
      }
    }
  }
</style>
