<template>
  <div class="questionnaire__image-selector">
    <div class="questionnaire__image-selector__row">
      <button
        :style="{'width': width, backgroundColor: itemColor(choice), paddingBottom: itemColor(choice) ? width : null}"
        class="questionnaire__image-selector__image"
        :class="[{'-selected': isSelected(choice), '-bordered': borders, '-zoom': !choice.next, '-has-color': !!itemColor(choice)}, elementClass]"
        :key="choice.value || choice.image || choice"
        :width="itemWidth"
        v-for="choice in options" @click="select(choice)"
      >
        <img v-if="!itemColor(choice)" :src="choice.image" :alt="choice.value">
      </button>
    </div>
    <continue-button @click.native="$emit('changed')" v-if="hasNext" :class="{'floating': selected.length && options.length > 10}" />
  </div>
</template>
<script>
import ContinueButton from './ContinueButton'
import {nameHexColor} from '../../../../services/color'
export default {
  components: { ContinueButton },
  props: {
    value: {
      type: [Array, Object, String],
      default: () => ([])
    },
    options: {
      type: Array,
      default: () => ([])
    },
    hasNext: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '24.5%'
    },
    compareKey: {
      type: String,
      default: 'image'
    },
    borders: {
      type: Boolean,
      default: true
    },
    elementClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    selected () {
      if (this.value && Array.isArray(this.value)) {
        return this.value
      }
      if (this.value && this.value['0']) {
        return Object.values(this.value)
      }
      return []
    },
    rowStyle () {
      if (`${this.width}`.endsWith('%')) {
        const count = ~~(100 / +this.width.replace(/[^\d.]/, ''))
        return {
          'grid-template-columns': `repeat(${count}, ${100 / count}fr)`
        }
      }
      return {}
    },
    itemWidth () {
      if (!`${this.width}`.endsWith('%')) {
        return this.width
      }
      return null
    }
  },
  methods: {
    colorName (choice) {
      if (choice && choice.color) {
        console.log(choice, nameHexColor(choice))
        return nameHexColor(choice)
      }
      return ''
    },
    isSelected (choice) {
      return !!this.selected.find(this.compare(choice))
    },
    itemColor (choice) {
      if (choice.color && choice.color.replace) {
        return choice.color.replace(/.*(#[\d\w]{3,6,8}).*/, '$1')
      }
      return null
    },
    compare (choice) {
      const compareKey = this.compareKey
      return option => {
        if (compareKey) {
          return option[compareKey] === choice[compareKey]
        }
        if (Object.keys(choice).length === 1) {
          const key = Object.keys(choice)[0]
          return option[key] === choice[key]
        }
        if (choice.image) {
          return option.image === choice.image
        }
        return JSON.stringify(option) === JSON.stringify(choice)
      }
    },
    select (choice) {
      const toArray = (val) => {
        if (Array.isArray(val)) {
          return val
        }
        return []
      }
      if (choice.next) {
        this.$emit('input', choice)
        this.$emit('changed')
      } else {
        if (!this.selected.find(this.compare(choice))) {
          this.$emit('input', [...toArray(this.value || []), choice])
        } else {
          const index = this.selected.findIndex(this.compare(choice))
          this.$emit('input', [
            ...toArray(this.value || []).slice(0, index),
            ...toArray(this.value || []).slice(index + 1)
          ])
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .floating {
    @include smaller-than(tablet) {
      border: 1px solid color(primary, $colors-border);
      z-index: 100;
      position: fixed;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .questionnaire__image-selector {
    position: relative;
    &__row {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      @include smaller-than(tablet) {
        display: block !important;
        text-align: center;
      }
    }
    &__image {
      display: inline-flex;
      align-content: center;
      justify-content: center;
      font-size: 16px;
      cursor: pointer;
      background-color: unset;
      margin: 1px;
      padding: 5px;
      outline: none;
      border: none;
      box-sizing: border-box;
      img {
        max-width: 100%;
      }

      &.-image {
        @include smaller-than(tablet) {
          width: 100% !important;
        }
      }
      &.-color {
        min-height: 50px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
        margin: 5px;
        padding: 0px;
        @include smaller-than(tablet) {
          width: 15.5% !important;
        }
      }
      &.-has-color {
        margin: 5px;
        padding: 0px;
        box-shadow: 0 0 2px lighten(color(primary), 50%);
      }
      &.-fabric {
        @include smaller-than(tablet) {
          width: 49% !important;
        }
      }
      &.-sleeve {
        @include smaller-than(tablet) {
          width: 32% !important;
          float:left;
        }
      }
      &.-neckline, &.-skirt {
        @include smaller-than(tablet) {
          width: 32% !important;
        }
      }

      &.-color:after {
        bottom: 15px;
        right: 12px;
      }
      &.-bordered {
        padding: 0;
        border: 1px solid color(territary, $colors-theme);
        border-radius: 3px;
        &.-selected {
          border-color: color(primary, $colors-theme);
          box-shadow: none;
        }
      }
      &.-selected {
        position: relative;

        &:after {
          position: absolute;
          bottom: 15px;
          right: 12px;
          content: '✓';
          border-radius: 50%;
          width: 20px;
          height: 20px;
          font-size: 14px;
          background-color: color(accent, $colors-theme, hover);
          border: 1px solid;
          color: color(primary, $colors-background);
          @include whiteframe()
        }

        &.-image:after {
          bottom: 20px;
          right: 20px;
        }

        &.-color:after {
          bottom: 15px;
          right: 12px;
        }
      }
    }
  }
</style>
