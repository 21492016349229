<template>
  <div class="question">
    <radio-buttons v-if="question.type === 'radiogroup'" @changed="onToNext" class="radio-buttons"
                   :options="question.choices" v-model="answer"
    />
    <image-selector v-if="question.type === 'images'" @changed="onToNext" :borders="false"
                    :options="question.choices" v-model="answer" width="32.5%" element-class="-image"
    />
    <image-selector v-if="question.type === 'colors'" @changed="onToNext" element-class="-color"
                    :options="question.choices" v-model="answer" width="11.5%" :borders="false"
    />
    <image-selector v-if="question.type === 'fabrics'" @changed="onToNext" element-class="-fabric"
                    :options="question.choices" v-model="answer" width="19.5%" :borders="false"
    />
    <explain-yourself-geller v-if="question.type === 'textarea'" @changed="onToNext"
                             v-model="answer"
    />
    <upload-image v-if="question.type === 'upload'" @changed="onToNext"
                  v-model="answer"
    />
    <div v-if="!question || !Object.keys(question).length">
      <h4> To start an new questionnaire, tick "Restart" button. Use "Back" button if you wish to change some answers.</h4>
    </div>
  </div>
</template>

<script>
import BaseQuestion from '../../../mixins/questionnaire/baseQuestion'
import RadioButtons from './Fields/RadioButtons.vue'
import ImageSelector from './Fields/ImageSelector.vue'
import ExplainYourselfGeller from './Fields/Textarea.vue'
import UploadImage from './Fields/UploadImage.vue'

export default {
  mixins: [BaseQuestion],
  components: {
    RadioButtons,
    ExplainYourselfGeller,
    UploadImage,
    ImageSelector
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
$mine-shaft: color(mine-shaft);
$white: color(white);

.question {
  width: 100%;
  &__single-button {
    width: 100%;
    display: block;
    padding: 20px 15%;
    border: 2px solid $mine-shaft;
    font-size: 16px;
    background-color: $white;
    cursor: pointer;
    outline: none;
    margin-bottom: 10px;
  }
  .radio-buttons {
    @include respond-to(tablet) {
      width: 50%;
      margin: 0 auto;
      padding: 0;
    }
  }
}
</style>
