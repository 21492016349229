<template xmlns:v-lazy="http://www.w3.org/1999/xhtml">
  <div>
    <div class="questionnaire__upload-image mb10 mt10"
         :class="{'-uploaded': paths && paths.length, '-has-drop-area': dropArea, '-full-width': fullWidth}"
    >
      <label :for="name">Please, click here or drop file</label>
      <input
        class="
            py10 w-100 border-box brdr-none brdr-bottom-1
            brdr-cl-primary h5 sans-serif
          "
        type="file"
        :multiple="multiple"
        :disabled="disabled"
        :required="required"
        :accept="accept"
        :name="name"
        focus
        ref="fileInput"
        @change="onFileSelected"
        :placeholder="label"
      >
      <div class="questionnaire__upload-image__result" :class="{'-multi': multiple}" v-if="paths">
        <img :src="path" alt="" v-for="path in paths" :key="path">
      </div>
      <span class="explain">Click again to remove or change picture</span>
    </div>
    <template v-if="validation">
      <span class="block cl-error h6 mt8" v-if="validation.condition">
        {{ validation.text }}
      </span>
    </template>
    <template v-else-if="validations">
      <span
        v-for="(rule, index) in validations"
        :key="index"
        v-if="rule.condition"
        class="block cl-error h6 mt8"
        data-testid="errorMessage"
      >
        {{ rule.text }}
      </span>
    </template>
    <continue-button @click.native="$emit('changed')" v-if="hasNext" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ContinueButton from './ContinueButton'
export default {
  components: { ContinueButton },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Object, Array],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    dropArea: {
      type: Boolean,
      default: true
    },
    hasNext: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Object,
      required: false,
      default: () => { }
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      inputFocus: false,
      inputValue: ''
    }
  },
  computed: {
    paths () {
      if (this.value && typeof this.value === 'string') {
        if (typeof window !== 'undefined' && window.location.host.includes('localhost')) {
          return ['http://localhost:5000' + this.value]
        }
        return [this.value]
      }
      if (this.value && Array.isArray(this.value) && this.value.length) {
        return this.value.map(path => {
          if (typeof window !== 'undefined' && window.location.host.includes('localhost')) {
            return 'http://localhost:5000' + path
          }
          return path
        })
      }
      return []
    }
  },
  methods: {
    ...mapActions('questionnaire', ['uploadPicture']),
    getMultipleName (files) {
      let names = [];

      [...files].forEach((file) => {
        names.push(file.name)
      })

      return names.join(', ')
    },
    openPicker () {
      if (!this.disabled) {
        this.resetFile()
        this.$refs.fileInput.click()
      }
    },
    resetFile () {
    },
    onFileSelected ($event) {
      const files = $event.target.files || $event.dataTransfer.files

      if (files) {
        if (files.length > 1) {
          this.filename = this.getMultipleName(files)
        } else if (files.length === 1) {
          this.filename = files[0].name
        } else {
          this.filename = null
        }
      } else {
        this.filename = $event.target.value.split('\\').pop()
      }
      const formData = new FormData()
      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append('image', files[i])
        }
      }
      this.uploadPicture(formData).then((paths) => {
        this.$emit('input', this.multiple ? paths : paths[0])
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  $color-tertiary: color(tertiary);
  $color-black: color(black);
  $color-hover: color(tertiary, $colors-background);

  $bg-secondary: color(secondary, $colors-background);
  $color-secondary: color(secondary);
  $color-error: color(error);

  $color-silver: color(silver);
  $color-active: color(secondary);
  $color-white: color(white);

  .questionnaire__upload-image {
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    .explain {
      display: none;
      position: absolute;
      top: 10px;
    }
    &.-uploaded {
      border-color: color(success, $colors-theme);
      label {
        display: none;
      }
      .explain {
        display: block;
      }
    }
    &.-has-drop-area {
      width: 100%;
      height: 80vh;
      border: 1px dashed color(secondary, $colors-theme);
      padding: 10px;
      box-sizing: border-box;
        @include respond-to(tablet) {
          &:not(.-full-width) {
            width: 80%;
            margin: 0 auto;
          }
          height: 450px;
      }
    }
    input[type="file"] {
      width: 100%;
      height: 100%;
      /*margin: -1px;*/
      padding: 0;
      overflow: hidden;
      position: absolute;
      opacity: 0;
      color: transparent;
      /*clip: rect(0 0 0 0);*/
      border: 0;
    }
    &__result {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
        max-height: 80%;
      }
      &.-multi {
        overflow: hidden;
        img {
          height: auto;
          max-height: 50%;
          margin: 10px;
        }
      }
    }
    &__caption {
      font-size: 14px;
      opacity: 0;
      text-align: left;
      &.-has-value {
        opacity: 1;
      }
    }
    .error {
      color: $color-error;
      padding-top: 5px;
      display: block;
      font-size: 12px;
    }
    label {
      margin-bottom: 5px;
      cursor: pointer;
      font-size: 16px;
      line-height: 30px;
      width: 100%;
      align-self: center;
      justify-self: center;
      display: block;
      text-align: center;
      position: absolute;
      box-sizing: border-box;
    }
  }
</style>
