<template>
  <div class="cloth-item" :class="{'-unprocessed': !shouldBeProcessed}"
       :style="itemStyle" :title="item['Element Name']"
       @click="$emit('click', $event)"
       @mouseenter="handleMouseover"
  >
    <div class="cloth-item__dummy" v-if="!imageReady" />
    <div class="cloth-item__wrapper" :style="dimensions" v-if="imageReady">
      <img v-if="shouldBeProcessed && imageUrl && image" :src="image" alt="">
      <img v-if="!shouldBeProcessed" :src="iconUrl" alt="">
    </div>
    <div class="cloth-item__name" v-if="showName">
      {{ item.similar && item.similar.length > 1 ? item.groupBy : item['Element Name'] }}
      {{ item.similar && item.similar.length > 1 ? `(${item.similar.length})` : '' }}
    </div>
  </div>
</template>
<script>
import { trimSvgImage, loadCORSImage } from '../../../../services/toDataURL'
import debounce from 'lodash.debounce'
export default {
  name: 'ClothPartRender',
  props: {
    item: {
      type: Object,
      default: () => ({
        Icons: [],
        Image: []
      })
    },
    height: {
      type: [String, Number],
      default: 'auto'
    },
    width: {
      type: [String, Number],
      default: 'auto'
    },
    minSize: {
      type: [String, Number],
      default: 150
    },
    showName: {
      type: Boolean,
      default: true
    },
    inputOnMouseover: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      image: '',
      canvas: null,
      imageReady: false
    }
  },
  mounted () {
    if (this.shouldBeProcessed) {
      if (this.imageUrl) {
        trimSvgImage(this.imageUrl).then(canvas => {
          this.canvas = canvas
          this.image = canvas.toDataURL('image/png')
          this.imageReady = true
          this.$emit('notify-size', {w: canvas.width / 3, h: canvas.height / 3})
        })
      }
    } else {
      loadCORSImage(this.iconUrl).then(() => {
        this.imageReady = true
      })
    }
  },
  methods: {
    handleMouseover: debounce(function (e) {
      if (this.inputOnMouseover) {
        this.$emit('click', e)
      }
    })
  },
  computed: {
    show () {
      return !this.shouldBeProcessed || this.image
    },
    dimensions () {
      return this.shouldBeProcessed ? {
        'height': `${this.height < this.minSize ? this.minSize : this.height}px`,
        'width': `${this.width < this.minSize ? this.minSize : this.width}px`
      } : {
        'width': 'auto'
      }
    },
    itemStyle () {
      return {
        'width': this.shouldBeProcessed ? `${this.width < this.minSize ? this.minSize : this.width}px` : null
      }
    },
    shouldBeProcessed () {
      return !this.item.Icons || !this.item.Icons.length
    },
    iconUrl () {
      if (!this.shouldBeProcessed && this.item.Icons[0]) {
        return this.item.Icons[0].url
      }
      return ''
    },
    imageUrl () {
      if (this.shouldBeProcessed && this.item.Image && this.item.Image[0] && this.item.Image[0].thumbnails) {
        return this.item.Image[0].thumbnails.large.url
      }
      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
  .cloth-item {
    display: inline-flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    background-color: unset;
    outline: none;
    border: none;
    margin: 5px;
    box-sizing: border-box;
    &__dummy {
      width: 150px;
      height: 178px;
      background-color: #3c3c3c;
    }
    &__name {
      padding: 5px;
      font-size: 14px;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 400;
    }
    &__wrapper {
      text-align: center;
      background-color: #3c3c3c;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      user-select: none;
      -webkit-user-drag: none;
      img {
        user-select: none;
        -webkit-user-drag: none;
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
      }
    }
    &.-unprocessed {
      width: 12%;
      .cloth-item__wrapper {
        background-color: transparent;
      }
    }
    &.-selected {
      position: relative;
      &:after {
        position: absolute;
        content: '✓';
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 14px;
        text-align: center;
        background-color: color(primary, $colors-background);
        border: 1px solid;
        font-weight: bold;
        color: color(accent, $colors-theme, hover);
        top: 10px;
        right: 5px;
      }
    }
    &.-image {
      @include smaller-than(tablet) {
        width: 100% !important;
      }
    }
  }
</style>
