<template>
  <div class="w-100">
    <header class="py35 pl20">
      <div class="container">
        <div class="row middle-sm center-xs cl-primary">
          <div class="col-xs-12 mb15">
            <h3>Please, describe your idea in the form below</h3>
          </div>
        </div>
      </div>
    </header>
    <div class="container pb60">
      <div class="row m0 pt15 questionnaire-end">
        <div class="col-xs-12 col-md-10 col-md-offset-1 image-upload mb30">
          <h5 class="center-xs">
            Inspiration pictures
          </h5>
          <upload-image v-model="inspiration" :has-next="false" multiple
                        :validation="{
                          condition: !$v.inspiration.required && $v.inspiration.$error,
                          text: 'Field is required.'
                        }"
          />
        </div>
        <div class="col-xs-12 col-md-8 col-md-offset-2">
          <base-input
            v-show="!isLoggedIn"
            class="col-xs-12 mb15"
            type="text"
            name="name"
            autocomplete="name"
            v-model="name"
            @blur="$v.name.$touch()"
            placeholder="Name"
            :validation="{
              condition: !$v.name.required && $v.name.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="date"
            name="date"
            autocomplete="date"
            v-model="date"
            @blur="$v.date.$touch()"
            placeholder="Date by when you need the dress"
            :validation="{
              condition: !$v.date.required && $v.date.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="date"
            name="eventDate"
            autocomplete="eventDate"
            v-model="eventDate"
            @blur="$v.eventDate.$touch()"
            placeholder="Event date (if applicable)"
            :validation="{
              condition: !$v.eventDate.required && $v.eventDate.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="eventType"
            autocomplete="eventType"
            v-model="eventType"
            @blur="$v.eventType.$touch()"
            placeholder="Event type (if applicable)"
            :validation="{
              condition: !$v.eventType.required && $v.eventType.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            v-show="!isLoggedIn"
            class="col-xs-12 mb15"
            type="text"
            name="email"
            autocomplete="email"
            v-model="email"
            @blur="$v.email.$touch()"
            placeholder="Email address"
            :validation="{
              condition: !$v.email.required && $v.email.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="phone"
            autocomplete="phone"
            v-model="phone"
            @blur="$v.phone.$touch()"
            placeholder="Phone number"
            :validation="{
              condition: !$v.phone.required && $v.phone.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="budget"
            autocomplete="budget"
            v-model="budget"
            @blur="$v.budget.$touch()"
            placeholder="Budget"
            :validation="{
              condition: !$v.budget.required && $v.budget.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="emphasize"
            autocomplete="emphasize"
            v-model="emphasize"
            @blur="$v.emphasize.$touch()"
            placeholder="Emphasize anything in particular? (waist, legs, cleavage, etc?)"
            :validation="{
              condition: !$v.emphasize.required && $v.emphasize.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="hide"
            autocomplete="hide"
            v-model="hide"
            @blur="$v.hide.$touch()"
            placeholder="Hide anything in particular? (waist, legs, cleavage, etc?)"
            :validation="{
              condition: !$v.hide.required && $v.hide.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="yeses"
            autocomplete="yeses"
            v-model="yeses"
            @blur="$v.yeses.$touch()"
            placeholder="Definite style yes's"
            :validation="{
              condition: !$v.yeses.required && $v.yeses.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="noes"
            autocomplete="noes"
            v-model="noes"
            @blur="$v.noes.$touch()"
            placeholder="Definite style no's"
            :validation="{
              condition: !$v.noes.required && $v.noes.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="frontNecklineStyle"
            autocomplete="frontNecklineStyle"
            v-model="frontNecklineStyle"
            @blur="$v.frontNecklineStyle.$touch()"
            placeholder="Front neckline style"
            :validation="{
              condition: !$v.frontNecklineStyle.required && $v.frontNecklineStyle.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="frontNecklineHeight"
            autocomplete="frontNecklineHeight"
            v-model="frontNecklineHeight"
            @blur="$v.frontNecklineHeight.$touch()"
            placeholder="Front neckline height"
            :validation="{
              condition: !$v.frontNecklineHeight.required && $v.frontNecklineHeight.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="backNecklineStyle"
            autocomplete="backNecklineStyle"
            v-model="backNecklineStyle"
            @blur="$v.backNecklineStyle.$touch()"
            placeholder="Back neckline style"
            :validation="{
              condition: !$v.backNecklineStyle.required && $v.backNecklineStyle.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="backNecklineHeight"
            autocomplete="backNecklineHeight"
            v-model="backNecklineHeight"
            @blur="$v.backNecklineHeight.$touch()"
            placeholder="Back neckline height"
            :validation="{
              condition: !$v.backNecklineHeight.required && $v.backNecklineHeight.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="skirtStyle"
            autocomplete="skirtStyle"
            v-model="skirtStyle"
            @blur="$v.skirtStyle.$touch()"
            placeholder="Skirt silhouette style"
            :validation="{
              condition: !$v.skirtStyle.required && $v.skirtStyle.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="waistSkirtHeight"
            autocomplete="waistSkirtHeight"
            v-model="waistSkirtHeight"
            @blur="$v.waistSkirtHeight.$touch()"
            placeholder="Waist/ skirt height (empire waist, natural waist, dropped waist, etc)"
            :validation="{
              condition: !$v.waistSkirtHeight.required && $v.waistSkirtHeight.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="skirtLengthFront"
            autocomplete="skirtLengthFront"
            v-model="skirtLengthFront"
            @blur="$v.skirtLengthFront.$touch()"
            placeholder="Skirt length front"
            :validation="{
              condition: !$v.skirtLengthFront.required && $v.skirtLengthFront.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="skirtLengthBack"
            autocomplete="skirtLengthBack"
            v-model="skirtLengthBack"
            @blur="$v.skirtLengthBack.$touch()"
            placeholder="Skirt length back"
            :validation="{
              condition: !$v.skirtLengthBack.required && $v.skirtLengthBack.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="sleeveStyle"
            autocomplete="sleeveStyle"
            v-model="sleeveStyle"
            @blur="$v.sleeveStyle.$touch()"
            placeholder="Sleeve style"
            :validation="{
              condition: !$v.sleeveStyle.required && $v.sleeveStyle.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="sleeveLength"
            autocomplete="sleeveLength"
            v-model="sleeveLength"
            @blur="$v.sleeveLength.$touch()"
            placeholder="Sleeve length"
            :validation="{
              condition: !$v.sleeveLength.required && $v.sleeveLength.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="shrug"
            autocomplete="shrug"
            v-model="shrug"
            @blur="$v.shrug.$touch()"
            placeholder="Do you want a shrug/scarf/belt?"
            :validation="{
              condition: !$v.shrug.required && $v.shrug.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="oneOrTwoPieces"
            autocomplete="oneOrTwoPieces"
            v-model="oneOrTwoPieces"
            @blur="$v.oneOrTwoPieces.$touch()"
            placeholder="One piece, two piece (dress or top and skirt)"
            :validation="{
              condition: !$v.oneOrTwoPieces.required && $v.oneOrTwoPieces.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="colorsPrints"
            autocomplete="colorsPrints"
            v-model="colorsPrints"
            @blur="$v.colorsPrints.$touch()"
            placeholder="Colors, fabric prints"
            :validation="{
              condition: !$v.colorsPrints.required && $v.colorsPrints.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="styleMood"
            autocomplete="styleMood"
            v-model="styleMood"
            @blur="$v.styleMood.$touch()"
            placeholder="Style mood- (conservative, sexy, professional, etc)"
            :validation="{
              condition: !$v.styleMood.required && $v.styleMood.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-12 mb15"
            type="text"
            name="pockets"
            autocomplete="pockets"
            v-model="pockets"
            @blur="$v.pockets.$touch()"
            placeholder="Pockets - yes or no (not always possible depending on skirt style and fabric)"
            :validation="{
              condition: !$v.pockets.required && $v.pockets.$error,
              text: 'Field is required.'
            }"
          />
          <div class="col-xs-12 image-upload">
            <h5 class="center-xs">
              Body pictures *
            </h5>
            <upload-image v-model="bodyPictures" :has-next="false"
                          :validation="{
                            condition: !$v.bodyPictures.required && $v.bodyPictures.$error,
                            text: 'Field is required.'
                          }"
                          multiple
            />
          </div>
          <div class="col-xs-12 image-upload">
            <h5 class="center-xs">
              Front picture and back picture nice headshot with smile *
            </h5>
            <upload-image v-model="headshot" :has-next="false"
                          :validation="{
                            condition: !$v.headshot.required && $v.headshot.$error,
                            text: 'Field is required.'
                          }"
                          multiple
            />
          </div>
          <button-full class="mt15" @click="sendForm">
            Continue
          </button-full>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import UploadImage from '../Fields/UploadImage'
import { required, alphaNum, email } from 'vuelidate/lib/validators'
import { trackEvent } from '../../../../utils/analytics'

export default {
  data () {
    return {
      agreed: true,
      authToken: '',
      dropInInstance: null,
      paymentIsValid: false,
      patternData: {},
      errors: [],
      name: '',
      date: '',
      eventDate: '',
      eventType: '',
      email: '',
      phone: '',
      budget: '',
      emphasize: '',
      hide: '',
      yeses: '',
      noes: '',
      frontNecklineStyle: '',
      frontNecklineHeight: '',
      backNecklineStyle: '',
      backNecklineHeight: '',
      skirtStyle: '',
      waistSkirtHeight: '',
      skirtLengthFront: '',
      skirtLengthBack: '',
      sleeveStyle: '',
      sleeveLength: '',
      shrug: '',
      oneOrTwoPieces: '',
      colorsPrints: '',
      styleMood: '',
      pockets: '',
      bodyPictures: '',
      headshot: '',
      inspiration: ''
    }
  },
  validations: {
    name: {
      required
    },
    date: {
      required
    },
    eventDate: {
    },
    eventType: {
    },
    email: {
      required,
      email
    },
    phone: {
      alphaNum,
      required
    },
    budget: {
      required
    },
    emphasize: {
      required
    },
    hide: {
      required
    },
    yeses: {
      required
    },
    noes: {
      required
    },
    frontNecklineStyle: {
      required
    },
    frontNecklineHeight: {
      required
    },
    backNecklineStyle: {
      required
    },
    backNecklineHeight: {
      required
    },
    skirtStyle: {
      required
    },
    waistSkirtHeight: {
      required
    },
    skirtLengthFront: {
      required
    },
    skirtLengthBack: {
      required
    },
    sleeveStyle: {
      required
    },
    sleeveLength: {
      required
    },
    shrug: {
      required
    },
    oneOrTwoPieces: {
      required
    },
    colorsPrints: {
      required
    },
    styleMood: {
      required
    },
    pockets: {
      required
    },
    bodyPictures: {
      required
    },
    headshot: {
      required
    },
    inspiration: {
    }
  },
  components: {
    UploadImage
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (typeof window !== 'undefined') {
        if (to.query.__em) {
          vm.$store.commit('referral/setInvitedState', to.query.__em)
        } else {
          vm.$store.dispatch('referral/loadCachedInviteState')
        }
        if (vm.$store.state.referral.invitedEmail && !vm.$store.getters['user/isLoggedIn']) {
          vm.$store.dispatch('referral/requestInviteInfo', vm.$store.state.referral.invitedEmail).then(() => {
            const info = vm.$store.state.referral.inviteInfo
            if (info && info.customer) {
              if (info.customer.firstname || info.customer.lastname) {
                vm.name = [info.customer.firstname || '', info.customer.lastname || ''].join(' ')
              }
              // that is crazy insecure enough
              if (info.customer.email) {
                vm.email = info.customer.email
              }
              if (info.customer.phone) {
                vm.phone = info.customer.phone
              }
              // if (info.checklist) {
              //   Object.keys(info.checklist).forEach(key => {
              //     vm[key] = info.checklist[key]
              //   })
              // }
            }
          })
        }
        if (vm.$store.getters['user/isLoggedIn']) {
          const info = vm.$store.state.user.current
          if (info) {
            if (info.firstname || info.lastname) {
              vm.name = [info.firstname || '', info.lastname || ''].join(' ')
            }
            // that is crazy insecure enough
            if (info.email) {
              vm.email = info.email
            }
            if (info.phone) {
              vm.phone = info.phone
            }
            // if (info.checklist) {
            //   Object.keys(info.checklist).forEach(key => {
            //     vm[key] = info.checklist[key]
            //   })
            // }
          }
        }
      }
    })
  },
  computed: {
    ...mapGetters('questionnaire', ['question', 'results', 'isComplete']),
    ...mapGetters('user', ['isLoggedIn']),
    canSubmit () {
      return this.paymentIsValid && this.agreed
    },
    results () {
      return {
        name: this.name,
        email: this.email,
        date: this.date,
        eventDate: this.eventDate,
        eventType: this.eventType,
        phone: this.phone,
        budget: this.budget,
        emphasize: this.emphasize,
        hide: this.hide,
        yeses: this.yeses,
        noes: this.noes,
        frontNecklineStyle: this.frontNecklineStyle,
        frontNecklineHeight: this.frontNecklineHeight,
        backNecklineStyle: this.backNecklineStyle,
        backNecklineHeight: this.backNecklineHeight,
        skirtStyle: this.skirtStyle,
        waistSkirtHeight: this.waistSkirtHeight,
        skirtLengthFront: this.skirtLengthFront,
        skirtLengthBack: this.skirtLengthBack,
        sleeveStyle: this.sleeveStyle,
        sleeveLength: this.sleeveLength,
        shrug: this.shrug,
        oneOrTwoPieces: this.oneOrTwoPieces,
        colorsPrints: this.colorsPrints,
        styleMood: this.styleMood,
        pockets: this.pockets,
        bodyPictures: this.bodyPictures,
        headshot: this.headshot,
        inspiration: this.inspiration
      }
    }
  },
  methods: {
    ...mapActions('questionnaire', ['obtainToken']),
    sendForm () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        trackEvent('user-action', 'questionnaire-validation-fail')
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: 'Please fix the validation errors',
          action1: { label: 'Ok' }
        })
      } else {
        this.$store.dispatch('referral/completeChecklist', this.results)
          .then(() => {
            this.$router.push('/consultation/thank-you')
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .questionnaire-pay {
    border-radius: 3px;
    color: color(primary, $colors-background);
    background-color: color(accent, $colors-theme, hover);
    padding: 10px 30px;
    border: none;
    &[disabled] {
      background-color: color(tertiary, $colors-theme);
      cursor: not-allowed;
    }
    &__wrapper {
      width: 100%;
      text-align: center;
    }
  }
</style>
